import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "components/Button/Button";
import s from "./Welcome.module.scss";

const Welcome = () => {
  const navigate = useNavigate();
  const keystore = localStorage.getItem("keystore");

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Welcome</title>
      </Helmet>
      <section className={s.login}>
        <div className={s.content}>
          <h1 className={s.title}>Welcome!</h1>
          <p className={s.desc}>Are you new in EcoWay?</p>
          <div className={s.ctaWrapper}>
            {keystore && (
              <Button
                size="big"
                variant="secondary"
                onClick={() => navigate("/login")}
              >
                No, I want to use existing wallet
              </Button>
            )}
            <Button
              size="big"
              variant="secondary"
              onClick={() => navigate("/import")}
            >
              No, I want to import my wallet
            </Button>
            <Button
              size="big"
              variant="primary"
              onClick={() => navigate("/create")}
            >
              Yes, I need new wallet
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
