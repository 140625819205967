import {
  authSlice,
  handleLogin,
  handleCreate,
  handleImport,
} from "./features/auth/authSlice";

import { walletSlice } from "./features/wallet/walletSlice";

export const walletActions = {
  ...walletSlice.actions,
};

export const authActions = {
  ...authSlice.actions,
  handleLogin,
  handleCreate,
  handleImport,
};

export * from "./store";
export * from "./hooks";
export * from "./context/DatabaseContextProvider";
