import { Route, Routes, Navigate } from "react-router";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cx from "classnames";
import { HelmetProvider } from "react-helmet-async";
import {
  SignedInHeader,
  Nav,
  PrivateRoute,
  Drawer,
  Header,
} from "./components";
import { ROUTES, PATHS } from "./constants";
import s from "./App.module.scss";

const App = () => {
  const location = useLocation();

  return (
    <HelmetProvider>
      <div className={cx(s.app, `page-${location.pathname.substring(1)}`)}>
        {location.pathname === "/login" ||
        location.pathname === "/login-welcome" ||
        location.pathname === "/welcome" ||
        location.pathname === "/create" ||
        location.pathname === "/import" ? (
          <Header />
        ) : (
          <SignedInHeader location={location} />
        )}
        <div className={s.contentHolder}>
          <div>
            <main>
              <Routes>
                {ROUTES.map(({ name, path, view: View, isPrivate }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      isPrivate ? <PrivateRoute Component={View} /> : <View />
                    }
                  >
                    {name}
                  </Route>
                ))}
                <Route path="/" element={<Navigate to={PATHS.HOME} />} />
              </Routes>
            </main>
          </div>
        </div>
        <Drawer />
        <Nav />
        <ToastContainer
          limit={3}
          newestOnTop
          draggablePercent={60}
          pauseOnHover
          autoClose={5000}
          position="bottom-right"
        />
      </div>
    </HelmetProvider>
  );
};

export default App;
