import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { ImportForm } from "components/ImportForm/ImportForm";
import { CongratulationsStep } from "components/CongratulationsStep/CongratulationsStep";
import s from "./Import.module.scss";

export const Import = () => {
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };
  const handlePrevStep = () => {
    setStep((prevState) => prevState - 1);
  };
  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Import wallet</title>
      </Helmet>
      <section className={s.import}>
        {step === 0 ? (
          <div className={s.wrapper}>
            <h1 className={s.title}>Import from Seed</h1>
            <ImportForm
              step={step}
              handlePrevStep={handlePrevStep}
              handleNextStep={handleNextStep}
            />
          </div>
        ) : null}
        {step === 1 ? <CongratulationsStep /> : null}
      </section>
    </>
  );
};
