import { configureStore } from "@reduxjs/toolkit";
import { walletSlice } from "./features/wallet/walletSlice";
import { authSlice } from "./features/auth/authSlice";
import { uiSlice } from "./features/ui/uiSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    wallet: walletSlice.reducer,
    ui: uiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
