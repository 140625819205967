import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CreateForm, ProgressBar } from "components";
import s from "./Create.module.scss";

export const Create = () => {
  const [step, setStep] = useState(0);

  const handleNextStep = () => {
    setStep((prevState) => prevState + 1);
  };
  const handlePrevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Create new wallet</title>
      </Helmet>
      <section className={s.create}>
        <div className={s.wrapper}>
          <div className={s.progressBarWrapper}>
            <ProgressBar stepsNumber={3} activeStep={step} />
          </div>
          <CreateForm
            step={step}
            handlePrevStep={handlePrevStep}
            handleNextStep={handleNextStep}
          />
        </div>
      </section>
    </>
  );
};
