/* eslint-disable */
import { Link, useNavigate } from "react-router-dom";
import s from "./HomeTileChia.module.scss";
import { ReactComponent as ChiaLogo } from "./img/chia.svg";
import { ReactComponent as ReceiveIcon } from "./img/receive.svg";
import { ReactComponent as SendIcon } from "./img/send.svg";
import { ReactComponent as ExternalLinkIcon } from "../../assets/icons/external-link.svg";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { Loader } from "components/Loader/Loader";

// TODO set chiaCoin object in types
interface IHomeTitleChia {
  chiaCoin: any;
  stakingGains: string;
  stakingGainsPending: boolean;
}

export const HomeTileChia = ({
  chiaCoin,
  stakingGains,
  stakingGainsPending,
}: IHomeTitleChia) => {
  return (
    <div className={s.tile}>
      <div className={s.left}>
        <div className={s.top}>
          <div className={s.content}>
            <span className={s.name}>Chia</span>
            <span className={s.value}>
              {chiaCoin.balance < 10000000000 ? "~" : ""}
              {Number(
                ethers.utils.formatUnits(chiaCoin.balance, chiaCoin.decimals)
              ).toFixed(2)}{" "}
              XCH
            </span>

            <span className={s.coinPrice}>
              $ {new BigNumber(chiaCoin?.usdValue).toFixed(2)}
            </span>
          </div>
          <div className={s.logo}>
            <ChiaLogo />
          </div>
        </div>{" "}
        <div className={s.body}>
          <div className={s.bodyLeft}>
            {stakingGainsPending || stakingGains === "" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "60px",
                  height: "60px",
                }}
              >
                <Loader />
              </div>
            ) : Number(stakingGains) > 0 ? (
              <>
                {/* <span className={s.percentages}>(+1.5%)</span> */}
                <span className={s.profit}>
                  {Number(stakingGains).toFixed(3)} XCH
                </span>
                <span className={s.desc}>Farmed</span>
              </>
            ) : (
              <>
                <a
                  className={s.ecoWayLink}
                  href="https://stake.ecoway.org/"
                  target="_blank"
                  rel="noopener"
                >
                  <span className={s.profit}>stake.ecoway.org</span>
                  <ExternalLinkIcon style={{ width: "22px", height: "22px" }} />
                </a>
                <span className={s.desc}>Let's stake ECY to get XCH</span>
              </>
            )}
          </div>
          <div className={s.bodyRight}>
            {/*<button*/}
            {/*  // onClick={() => navigate(`/dashboard/details/${chiaCoin.id}`)}*/}
            {/*  className={s.detailsLink}*/}
            {/*>*/}
            {/*  See details*/}
            {/*</button>*/}
          </div>
        </div>
      </div>
      <div className={s.right}>
        <Link className={s.btn} to="/send">
          <ReceiveIcon />
          <span className={s.btnDesc}>Send</span>
        </Link>
        <Link className={s.btn} to="/receive">
          {" "}
          <SendIcon />
          <span className={s.btnDesc}>Receive</span>
        </Link>
      </div>
    </div>
  );
};
