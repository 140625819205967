type FilterItem = {
  name: string;
};

export const filterByName = (
  arrayToFilter: FilterItem[],
  searchValue: string
) => [
  ...arrayToFilter.filter((item: FilterItem) =>
    item.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
  ),
];

//   return [];
