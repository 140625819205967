/* eslint-disable */
import s from "./SendAddressBookList.module.scss";

type SendAddressBookListItemProps = {
  user: databaseAddressBookType;
  onSelect: (address: string) => void;
};

export const SendAddressBookListItem = ({
  user,
  onSelect,
}: SendAddressBookListItemProps) => (
  <li
    key={user.id}
    className={s.listItem}
    onClick={() => onSelect(user.address)}
  >
    <div className={s.avatar}>
      <span className={s.avatarLetter}>{user.name[0]}</span>
    </div>
    <div className={s.body}>
      <div className={s.content}>
        <span className={s.name}>{user.name}</span>
        <span className={s.id}>{user.address}</span>
      </div>
    </div>
  </li>
);
