/* eslint-disable */

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Plugin } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import s from "components/RoundedChart/RoundedChart.module.scss";
import { useAppSelector } from "../../redux";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

type EmptyDoughnutPluginOptions = {
  color: string;
  width: number;
  radiusDecrease: number;
};

const emptyDoughnutPlugin: Plugin<"doughnut", EmptyDoughnutPluginOptions> = {
  id: "emptyDoughnut",
  afterDraw(chart, args, options) {
    const { datasets } = chart.data;
    const { color, width, radiusDecrease } = options;
    let hasData = false;

    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      hasData = dataset.data.length > 0;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth = width || 2;
      ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
      ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
};

ChartJS.register(ArcElement, Tooltip);

type Coins = {
  value: number;
  color: string;
};

type RoundedChartProps = {
  coins: Coins[];
  labels: string[];
};

export const RoundedChart = ({ coins, labels }: RoundedChartProps) => {
  const coinValues = coins.map((item: Coins) => item.value);
  const coinColors = coins.map((item: Coins) => item.color);
  const totalValue = useAppSelector((state) => state.wallet?.totalUsdBalance);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "value of $",
        data: coinValues,
        backgroundColor: coinColors,

        borderWidth: 0,
        cutout: "80%",
        rotation: 180,
      },
    ],
  };

  return (
    <div className={s.wrapper}>
      <div className={s.chartInner}>
        <div className={s.chartValue}>
          $ {new BigNumber(totalValue).toFixed(2)}
        </div>
        <span className={s.chartDesc}>Total Value</span>
      </div>
      <Doughnut
        data={data}
        options={{
          plugins: {
            // @ts-ignore
            emptyDoughnut: {
              color: "#00A3BE",
              width: 20,
              radiusDecrease: 10,
            },
          },
        }}
        plugins={[emptyDoughnutPlugin]}
      />
    </div>
  );
};
