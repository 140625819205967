/* eslint-disable */
import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useForm } from "react-hook-form";
import s from "components/ImportForm/ImportForm.module.scss";
import { Button } from "components/Button/Button";
import { FormInput } from "components/FormInput/FormInput";
import { Checkbox } from "components/Checkbox/Checkbox";
import cx from "classnames";
import passwordIconShow from "./img/password-show.svg";
import passwordIcon from "./img/password.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { handleImport } from "../../redux/features/auth/authSlice";

type ImportFormProps = {
  step: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
};

type TFormValues = {
  password: string;
  confirmPassword: string;
  checkbox02: boolean;
  checkbox03: boolean;
  secret: string;
};

const passwordValidation = {
  required: {
    value: true,
    message: "Password cannot be empty",
  },
  pattern: {
    value:
      /^(?=.{8,32}$)(?=[^A-Z]*[A-Z])(?=\D*\d)(?=(?:[^a-z]*[a-z]))(?:[a-z]|[A-Z])[a-zA-Z0-9-!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+$/,
    message:
      "Must consist of 8-32 digits with number, upper and lower case letters",
  },
};

const checkboxValidation = {
  required: {
    value: true,
    message: "Please check this box if you want to proceed",
  },
};

const secretValidation = {
  required: {
    value: true,
    message: "Secret Recover Phrase cannot be empty",
  },
};

export const ImportForm = ({
  step,
  handleNextStep,
  handlePrevStep,
}: ImportFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
    setFocus,
    setError,
  } = useForm<TFormValues>({
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [showWords, setShowWords] = useState(true);
  const [isRecoveryPhaseFocused, setIsRecoveryPhaseIsFocused] = useState(false);

  const [passwordInputType, setPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] =
    useState("password");

  const isLoading = useAppSelector((state) => state.auth.isLoading);
  const dispatch = useAppDispatch();
  const password = useRef({});
  password.current = watch("password", "");

  const handleOnSubmit = async (values: TFormValues) => {
    try {
      const { password, secret } = values;
      const dataToImportWallet = { password, mnemonic: secret };
      await dispatch(handleImport(dataToImportWallet)).unwrap();
      handleNextStep();
    } catch (err: any) {
      setError("secret", { type: "custom", message: err?.message });
    }
  };

  const confirmPasswordValidation = {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    validate: (value: string) =>
      value === password.current || "The passwords do not match",
  };

  const passwordRegister = register("password", {
    ...passwordValidation,
  });
  const confirmPasswordRegister = register("confirmPassword", {
    ...confirmPasswordValidation,
  });

  const secretRegister = register("secret", {
    ...secretValidation,
  });

  const checkbox02Register = register("checkbox02", {
    ...checkboxValidation,
  });
  const checkbox03Register = register("checkbox03", {
    ...checkboxValidation,
  });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={s.form}>
      <div className={s.formWrapper}>
        <span className={s.boardTitle}>Secret Recover Phrase</span>
        <div className={s.board}>
          <span
            onClick={() => setShowWords((prev) => !prev)}
            className={s.passwordIcon}
          >
            <img
              className={s.icon}
              src={showWords ? passwordIcon : passwordIconShow}
              alt="password"
            />
          </span>
          <textarea
            className={cx(s.secretField, showWords ? s.active : null)}
            id="secret"
            name="secret"
            rows={3}
            cols={3}
            placeholder="---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----"
            onChange={secretRegister.onChange}
            ref={secretRegister.ref}
            onBlur={secretRegister.onBlur}
            onFocus={() => setIsRecoveryPhaseIsFocused(true)}
          />
          {!isRecoveryPhaseFocused && (
            <div
              className={s.recoveryPhaseInputInfo}
              role="button"
              onClick={() => {
                setIsRecoveryPhaseIsFocused(true);
                setFocus("secret");
              }}
            >
              <p style={{ padding: "0 15px", textAlign: "center" }}>
                Please write here secret recovery phrase
              </p>
            </div>
          )}
        </div>
        {errors.secret ? (
          <span className={s.error}>{errors.secret?.message as ReactNode}</span>
        ) : null}
        <div className={s.body}>
          <div className={s.inputWrapper}>
            <FormInput
              placeholder=" "
              type={passwordInputType}
              name="password"
              label="New Password"
              desc="min. 8 characters"
              onChange={passwordRegister.onChange}
              onBlur={passwordRegister.onBlur}
              ref={passwordRegister.ref}
              errors={errors}
              variant="password"
              onClick={() =>
                setPasswordInputType((prevState) =>
                  prevState === "password" ? "text" : "password"
                )
              }
            />
          </div>
          <div className={s.inputWrapper}>
            <FormInput
              variant="password"
              placeholder=" "
              type={confirmPasswordInputType}
              name="confirmPassword"
              label="Confirm New Password"
              onChange={confirmPasswordRegister.onChange}
              onBlur={confirmPasswordRegister.onBlur}
              ref={confirmPasswordRegister.ref}
              errors={errors}
              onClick={() =>
                setConfirmPasswordInputType((prevState) =>
                  prevState === "password" ? "text" : "password"
                )
              }
            />
          </div>
          <div className={s.checkboxWrapper}>
            <Checkbox
              onChange={checkbox02Register.onChange}
              ref={checkbox02Register.ref}
              onBlur={checkbox02Register.onBlur}
              name="checkbox02"
              errors={errors}
            >
              I have read and agree for a&nbsp;<a href="/#TODO">Term of Use</a>
            </Checkbox>
            <Checkbox
              onChange={checkbox03Register.onChange}
              ref={checkbox03Register.ref}
              onBlur={checkbox03Register.onBlur}
              name="checkbox03"
              errors={errors}
            >
              I understand that EcoWay cannot recover this password for me.
            </Checkbox>
          </div>
        </div>
        <div className={s.ctaGroup}>
          <Button
            size="big"
            variant="primary"
            type="submit"
            isLoading={isLoading}
          >
            Import
          </Button>
        </div>
      </div>
    </form>
  );
};
