import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { generationUtils } from "@nextrope/chia-wallet-sdk";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux";
import { PATHS } from "../../constants";
import { SecondStep } from "./SecondStep";
import { FirstStep } from "./FirstStep";
import { ThirdStep } from "./ThirdStep";
import s from "./CreateForm.module.scss";
import { handleCreate } from "../../redux/features/auth/authSlice";

const passwordValidation = {
  required: {
    value: true,
    message: "The password is required",
  },
  pattern: {
    value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
    message:
      "Must consist of 8-32 digits with number, upper and lower case letters",
  },
};

const checkboxValidation = {
  required: {
    value: true,
    message: "Please check this box if you want to proceed",
  },
};

interface CreateFormProps {
  step: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
}

export const CreateForm = ({
  step,
  handleNextStep,
  handlePrevStep,
}: CreateFormProps) => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector((state) => state.auth?.isAuth);
  const navigate = useNavigate();
  const [checkPhrase, setCheckPhrase] = useState(false);
  const [selected, setSelected] = useState<wordType[]>([]);
  const [mnemonicWords, setMnemonicWords] = useState<string>("");
  const [wordToPick, setWordToPick] = useState<wordType[]>([]);
  const passwordRef = useRef({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateFormData>({
    mode: "onSubmit",
    // reValidateMode: "onChange",
  });

  passwordRef.current = watch("password", "");

  useEffect(() => {
    const selectedWords = selected.map((word) => word.name).join(" ");

    if (mnemonicWords && mnemonicWords.localeCompare(selectedWords) === 0) {
      setCheckPhrase(true);
    }
  }, [selected]);

  const addToSelect = (selectedWord: wordType) => {
    setSelected((prevState): wordType[] => [...prevState, selectedWord]);
    setWordToPick(
      wordToPick?.filter(({ name }: wordType) => name !== selectedWord.name)
    );
  };

  const removeFromSelect = (selectedWord: wordType) => {
    setSelected(
      selected?.filter(({ name }: wordType) => name !== selectedWord.name)
    );
    setWordToPick((prevState): wordType[] => [...prevState, selectedWord]);
  };

  const generateMnemonic = () => {
    const textToDisplay = generationUtils.generateMnemonic();
    setMnemonicWords(textToDisplay);
    const textToArray = textToDisplay.split(" ");
    const words = textToArray.map((word) => ({ name: word }));

    const shuffledWords = words.sort(() => Math.random() - 0.5);
    setWordToPick([...shuffledWords]);
  };

  const handleBack = () => {
    setMnemonicWords("");
    setSelected([]);
    handlePrevStep();
  };

  const confirmPasswordValidation = {
    required: {
      value: true,
      message: "The password is required",
    },
    validate: (value: string) =>
      value === passwordRef.current || "The passwords do not match",
  };

  const passwordRegister = register("password", {
    ...passwordValidation,
  });

  const confirmPasswordRegister = register("confirmPassword", {
    ...confirmPasswordValidation,
  });

  // const checkbox01Register = register("checkbox01", {
  //   ...checkboxValidation,
  // });

  const checkbox02Register = register("checkbox02", {
    ...checkboxValidation,
  });

  const checkbox03Register = register("checkbox03", {
    ...checkboxValidation,
  });

  const handleOnSubmit = async (values: CreateFormData): Promise<void> => {
    try {
      const { password } = values;
      await dispatch(handleCreate({ password, mnemonic: mnemonicWords }));
    } catch {
      toast.error("Failed to create wallet, try again later.");
    }
  };

  useEffect(() => {
    if (isAuth) {
      navigate(PATHS.HOME);
    }
  }, [isAuth]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} className={s.form}>
      {step === 0 ? (
        <FirstStep
          generateMnemonic={generateMnemonic}
          handleNextStep={handleNextStep}
          mnemonicWords={mnemonicWords}
          textToDisplay={mnemonicWords}
        />
      ) : null}
      {step === 1 ? (
        <SecondStep
          selected={selected}
          wordToPick={wordToPick}
          addToSelect={addToSelect}
          removeFromSelect={removeFromSelect}
          handlePrevStep={handleBack}
          handleNextStep={handleNextStep}
          checkPhrase={!checkPhrase}
        />
      ) : null}
      {step === 2 ? (
        <ThirdStep
          confirmPasswordRegister={confirmPasswordRegister}
          passwordRegister={passwordRegister}
          errors={errors}
          // checkbox01Register={checkbox01Register}
          checkbox02Register={checkbox02Register}
          checkbox03Register={checkbox03Register}
        />
      ) : null}
    </form>
  );
};
