/* eslint-disable */
import { createAction, createSlice, Slice } from "@reduxjs/toolkit";

type ThemeMode = "dark" | "light";

type TypeOfDrawerType =
  | "2fa"
  | "address-book-remove"
  | "transactions-filter-date"
  | "transactions-filter-coin"
  | "transactions-filter-amount"
  | "transactions-filter-status"
  | "transactions-see-details"
  | "transactions-send-tokens";

type DrawerType = {
  isVisible: boolean;
  data: null | any;
  type: TypeOfDrawerType | null;
};

type FilterPropType = {
  dateFrom: null | string;
  dateTo: null | string;
  maxAmount: null | string;
  minAmount: null | string;
  coin: string;
};

interface UiType {
  theme: ThemeMode;
  drawer: DrawerType;
  filterData: FilterPropType;
}

export const showDrawer = createAction(
  "ui/showDrawer",
  function prepare(type, data?) {
    return {
      payload: { type, data },
    };
  }
);

export const hideDrawer = createAction("ui/hideDrawer");

export const setTheme = createAction(
  "ui/setTheme",
  function prepare(theme: ThemeMode) {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    return {
      payload: theme,
    };
  }
);

export const setDataFilter = createAction(
  "ui/setData",
  (data: { dateFrom: string | null; dateTo: string | null }) => {
    return {
      payload: data,
    };
  }
);

export const setAmountFilter = createAction(
  "ui/setAmount",
  (data: { minAmount: string | null; maxAmount: string | null }) => {
    return {
      payload: data,
    };
  }
);

export const setCoinFilter = createAction(
  "ui/setCoin",
  (data: { coin: string }) => {
    return {
      payload: data,
    };
  }
);

export const setFilter = createAction(
  "ui/setFilter",
  (data: FilterPropType) => {
    return {
      payload: data,
    };
  }
);

export const clearFilter = createAction("ui/clearFilter", () => {
  return {
    payload: {
      dateFrom: null,
      dateTo: null,
      maxAmount: null,
      minAmount: null,
      coin: "all",
    },
  };
});

const initialState: UiType = {
  theme: "light",
  drawer: {
    isVisible: false,
    data: null,
    type: null,
  },
  filterData: {
    dateFrom: null,
    dateTo: null,
    maxAmount: null,
    minAmount: null,
    coin: "all",
  },
};

export const uiSlice: Slice<UiType> = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTheme, (state, { payload }) => {
      state.theme = payload;
    });
    builder.addCase(showDrawer, (state, { payload }) => {
      const { drawer } = state;
      const { data, type } = payload;
      drawer.isVisible = true;
      drawer.data = data;
      drawer.type = type;
    });
    builder.addCase(hideDrawer, (state) => {
      const { drawer } = state;
      drawer.isVisible = false;
    });
    builder.addCase(setDataFilter, (state, { payload }) => {
      const { filterData } = state;

      filterData.dateFrom = payload.dateFrom;
      filterData.dateTo = payload.dateTo;
    });
    builder.addCase(setAmountFilter, (state, { payload }) => {
      const { filterData } = state;

      filterData.maxAmount = payload.maxAmount;
      filterData.minAmount = payload.minAmount;
    });

    builder.addCase(setCoinFilter, (state, { payload }) => {
      const { filterData } = state;

      filterData.coin = payload.coin;
    });
    builder.addCase(setFilter, (state, { payload }) => {
      state.filterData = payload;
    });
    builder.addCase(clearFilter, (state, { payload }) => {
      state.filterData = payload;
    });
  },
});
