import React from "react";
import cx from "classnames";
import s from "./ProgressBar.module.scss";

interface ProgressBarProps {
  activeStep: number;
  stepsNumber: number;
}

export const ProgressBar = ({ activeStep, stepsNumber }: ProgressBarProps) => {
  const lastBullet = stepsNumber - 1;
  const steps = Array.from({ length: stepsNumber });
  return (
    <div className={s.progressBar}>
      {steps.map((_, i) => (
        <React.Fragment key={`progress-step-${stepsNumber + i}`}>
          <div>
            <div
              className={cx(
                s.bullet,
                activeStep === i ? s.active : null,
                activeStep > i ? s.fill : null
              )}
            >
              {i + 1}
            </div>
          </div>
          {lastBullet !== i ? <span className={s.line} /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};
