import React from "react";
import { useFormContext } from "react-hook-form";
import s from "./TransactionsActiveFilter.module.scss";
import { FilterPropType } from "./Transactions";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { useAppDispatch } from "../../redux";
import {
  clearFilter,
  setAmountFilter,
  setCoinFilter,
  setDataFilter,
} from "../../redux/features/ui/uiSlice";

type TransactionsActiveFiltersProps = {
  handleClearFilters: (type: string) => void;
  activeFilters: FilterPropType;
};

export const TransactionsActiveFilter = ({
  handleClearFilters,
  activeFilters,
}: TransactionsActiveFiltersProps) => {
  const { reset } = useFormContext();
  const dispatch = useAppDispatch();

  const handleResetFilter = (type: string) => {
    switch (type) {
      case "date":
        reset({
          dateFrom: "",
          dateTo: "",
          coin: activeFilters.coin,
        });
        handleClearFilters(type);
        dispatch(setDataFilter({ dateFrom: null, dateTo: null }));
        break;
      case "amount":
        reset({
          maxAmount: "",
          minAmount: "",
          coin: activeFilters.coin,
        });
        handleClearFilters(type);
        dispatch(setAmountFilter({ maxAmount: null, minAmount: null }));
        break;
      case "coin":
        reset({
          coin: "all",
        });
        handleClearFilters(type);
        dispatch(setCoinFilter({ coin: "all" }));
        break;
      case "all":
        reset({
          dateFrom: "",
          dateTo: "",
          maxAmount: "",
          minAmount: "",
          coin: "all",
        });
        handleClearFilters(type);
        dispatch(clearFilter());
        break;
      default:
        break;
    }
  };

  return (
    <div className={s.activeFilterWrapper}>
      <div className={s.activeFilters}>
        {activeFilters.dateTo || activeFilters.dateFrom ? (
          <div className={s.tab} onClick={() => handleResetFilter("date")}>
            Date
            <CrossIcon />
          </div>
        ) : null}
        {activeFilters.maxAmount || activeFilters.minAmount ? (
          <div className={s.tab} onClick={() => handleResetFilter("amount")}>
            Amount
            <CrossIcon />
          </div>
        ) : null}
        {activeFilters.coin !== "all" ? (
          <div className={s.tab} onClick={() => handleResetFilter("coin")}>
            Coin
            <CrossIcon />
          </div>
        ) : null}
      </div>
      {activeFilters.dateFrom ||
      activeFilters.dateTo ||
      activeFilters.coin !== "all" ||
      activeFilters.minAmount ||
      activeFilters.maxAmount ? (
        <button
          className={s.clearFilter}
          type="button"
          onClick={() => handleResetFilter("all")}
        >
          Clear all filters
        </button>
      ) : (
        <p>All transactions</p>
      )}
    </div>
  );
};
