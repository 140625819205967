import React from "react";
import { useNavigate } from "react-router-dom";
import s from "components/CongratulationsStep/CongratulationsStep.module.scss";
import { Button } from "components/Button/Button";
import { PATHS } from "../../constants/routes";

export const CongratulationsStep = () => {
  const navigation = useNavigate();
  const onRedirectToDashboard = () => navigation(PATHS.HOME, { replace: true });
  return (
    <section className={s.gratulations}>
      <div className={s.content}>
        <h1 className={s.title}>Congratulations</h1>
        <p className={s.desc}>
          You&apos;ve successfully protected your wallet.{" "}
          <span>
            Remember to keep your seed phrase safe, it&apos;s your
            responsibility!
          </span>
        </p>
        <h2 className={s.subtitle}>Tips on storing it safety:</h2>
        <p className={s.desc}>Save a backup in multiple places</p>

        <p className={s.desc}>- Never share the phrase with anyone</p>
      </div>
      <div className={s.buttonWrapper}>
        <Button
          size="big"
          variant="primary"
          type="button"
          onClick={onRedirectToDashboard}
        >
          All done
        </Button>
      </div>
    </section>
  );
};
