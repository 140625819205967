/* eslint-disable */
import s from "./TransactionsFilter.module.scss";
import cx from "classnames";
import { useAppDispatch, useAppSelector } from "../../redux";
import {
  setAmountFilter,
  setCoinFilter,
  setDataFilter,
  showDrawer,
} from "../../redux/features/ui/uiSlice";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";

type Tab = {
  id: number;
  name: string;
  type: string;
  tabType?: string;
};

type TransactionsFilterType = {
  filterTabs: Tab[];
};

export const TransactionsFilter = ({ filterTabs }: TransactionsFilterType) => {
  const dispatch = useAppDispatch();
  const { dateFrom, dateTo, maxAmount, minAmount, coin } = useAppSelector(
    (state) => state?.ui?.filterData
  );

  const handleActiveClassName = (tabName: string) => {
    if (tabName === "Coin" && coin !== "all") {
      return s.active;
    }
    if (tabName === "Amount" && (maxAmount || minAmount)) {
      return s.active;
    }
    if (tabName === "Date" && (dateFrom || dateTo)) {
      return s.active;
    }
  };

  const isTabActive = (tabName: string) => {
    if (tabName === "Coin" && coin !== "all") {
      return true;
    }
    if (tabName === "Amount" && (maxAmount || minAmount)) {
      return true;
    }
    if (tabName === "Date" && (dateFrom || dateTo)) {
      return true;
    }
  };

  const handleClearFilter = (name: string) => {
    if (name === "Coin") {
      dispatch(setCoinFilter({ coin: "all" }));
    }
    if (name === "Amount") {
      dispatch(setAmountFilter({ maxAmount: "", minAmount: "" }));
    }
    if (name === "Date") {
      dispatch(setDataFilter({ dateFrom: "", dateTo: "" }));
    }
  };

  const handleClearAllFilters = () => {
    dispatch(setAmountFilter({ maxAmount: "", minAmount: "" }));
    dispatch(setCoinFilter({ coin: "all" }));
    dispatch(setDataFilter({ dateFrom: "", dateTo: "" }));
  };

  return (
    <nav className={s.inner}>
      {filterTabs.map((tab) =>
        tab.type === "filter" ? (
          <button
            onClick={() =>
              isTabActive(tab.name)
                ? handleClearFilter(tab.name)
                : dispatch(
                    showDrawer(`transactions-filter-${tab.tabType}`, {
                      onClick: () => {},
                    })
                  )
            }
            className={cx(s.tab, handleActiveClassName(tab.name))}
            key={tab.id}
          >
            {tab.name}
            {isTabActive(tab.name) ? <CrossIcon /> : null}
          </button>
        ) : (
          <button className={s.tabClear} key={tab.id}>
            {tab.name}
          </button>
        )
      )}
      <button onClick={handleClearAllFilters} className={cx(s.tab, s.active)}>
        Clear all filters
      </button>
    </nav>
  );
};
