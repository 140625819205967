import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { DatabaseManagerContext, store } from "./redux";
import "./sass/_index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Router>
      <React.StrictMode>
        <DatabaseManagerContext>
          <App />
        </DatabaseManagerContext>
      </React.StrictMode>
    </Router>
  </Provider>
);
