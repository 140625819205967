import { ComponentPropsWithoutRef, forwardRef } from "react";
import cx from "classnames";

import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg";
import s from "./BaseCheckbox.module.scss";

type BaseCheckboxProps = {
  id: string;
  label: string;
  wrapperProps?: ComponentPropsWithoutRef<"div">;
} & ComponentPropsWithoutRef<"input">;

export const BaseCheckbox = forwardRef<HTMLInputElement, BaseCheckboxProps>(
  (props, ref) => {
    const { label, wrapperProps, id, className, ...restInputProps } = props;

    return (
      <div {...wrapperProps} className={cx(s.wrapper, wrapperProps?.className)}>
        <input
          id={id}
          type="checkbox"
          ref={ref}
          className={cx(s.wrapper__input, className)}
          {...restInputProps}
        />
        <CheckIcon className={s.wrapper__checkIcon} />
        <label htmlFor={id} className={s.wrapper__label}>
          {label}
        </label>
      </div>
    );
  }
);
