import { NavLink } from "react-router-dom";
import s from "./Header.module.scss";
import EcowayLogo from "../../assets/ecoway-logo.svg";

export const Header = () => (
  <header className={s.wrapper}>
    <div className={s.header}>
      <NavLink className={s.logo} to="/welcome">
        <img src={EcowayLogo} alt="ecoway" />
      </NavLink>
    </div>
  </header>
);
