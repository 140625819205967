import { useState } from "react";
import { Button } from "..";
import { FormHeader } from "./FormHeader";
import crossIcon from "../../assets/icons/cross.svg";
import s from "./SecondStep.module.scss";

interface SecondStepProps {
  selected: wordType[];
  wordToPick: wordType[];
  addToSelect: (props: wordType) => void;
  removeFromSelect: (props: wordType) => void;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  checkPhrase: boolean;
}

export const SecondStep = ({
  selected,
  wordToPick,
  addToSelect,
  removeFromSelect,
  handlePrevStep,
  handleNextStep,
  checkPhrase,
}: SecondStepProps) => {
  const [isValid, setIsValid] = useState(false);

  const onHandleSubmit = () => {
    if (checkPhrase) {
      setIsValid(true);
      return;
    }
    handleNextStep();
  };
  return (
    <>
      <FormHeader
        title="Confirm your secret backup phrases"
        desc="Please select each phrase in order to make sure it is correct. "
      />
      <div className={s.formWrapper}>
        <div className={s.board}>
          <div className={s.selectedWrapper}>
            {selected.map((word: wordType) => (
              <button
                onClick={() => removeFromSelect(word)}
                key={`selected-word-${word.name}`}
                className={s.wordBtn}
                type="button"
              >
                <span>{word.name}</span>
                <img src={crossIcon} alt="" />
              </button>
            ))}
          </div>
        </div>
        {isValid && <span className="error">Phrase is incorrect </span>}
        <div className={s.body}>
          <div className={s.wordsWrapper}>
            {wordToPick.map((word: wordType) => (
              <span
                key={`pick-word-${word.name}`}
                onClick={() => addToSelect(word)}
                className={s.wordBtn}
              >
                {word.name}
              </span>
            ))}
          </div>
        </div>
        <div className={s.ctaGroup}>
          <Button size="small" variant="secondary" onClick={handlePrevStep}>
            Back
          </Button>
          <Button
            size="small"
            variant="primary"
            onClick={onHandleSubmit}
            type="button"
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
