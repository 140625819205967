/* eslint-disable */
import React, { useState, useRef } from "react";
import { sortUserAlphabetically } from "helpers/sortUserAlphabetically";
import { groupedByFirstCharacter } from "helpers/groupByFirstCharacter";
import s from "./AddressBookList.module.scss";
import { AddressBookListItem } from "./AddressBookListItem";
import { useOnClickOutside } from "../../hooks";
import { config } from "../../config";
import { useDatabaseContext } from "../../redux";
import { GenericObjectID } from "../../helpers";
import { toast } from "react-toastify";

const initialEditUserState = {
  id: "",
  isEdited: false,
  initialValue: "",
  editValue: "",
  address: "",
};
const initialModalState = {
  id: "",
  open: false,
};

export type EditUserType = {
  id: string | number;
  isEdited: boolean;
  initialValue: string;
  editValue?: string;
};

type AddressBookListProps = {
  userData: databaseAddressBookType[];
  getAddressBook: () => void;
};

export type ModalState = {
  open: boolean;
  id: string | number;
};

export const AddressBookList = ({
  userData,
  getAddressBook,
}: AddressBookListProps) => {
  const [editUser, setEditUser] = useState<EditUserType>(initialEditUserState);
  const sortedUsers = groupedByFirstCharacter(sortUserAlphabetically(userData));
  const [openModal, setOpenModal] = useState<ModalState>({
    open: false,
    id: "",
  });

  const { db, databaseIsReady } = useDatabaseContext();

  const modalRef = useRef();

  useOnClickOutside(modalRef, () => setOpenModal(initialModalState));

  const handleCloseModal = () => {
    setOpenModal(initialModalState);
  };

  const handleOpenModal = (user: databaseAddressBookType) => {
    if (user.id === openModal.id) {
      setOpenModal({
        open: !openModal.open,
        id: user.id,
      });
    } else {
      setOpenModal({
        open: true,
        id: user.id,
      });
    }
  };

  const handleRemoveUser = async (id: GenericObjectID) => {
    try {
      if (databaseIsReady) {
        await db?.deleteObject(config.DB_STORE_ADDRESS_BOOK, id);
        getAddressBook();
      }
    } catch (error) {
      toast.error("Record could not be deleted, try again later.");
    }
  };

  const handleEditUser = (user: databaseAddressBookType) => {
    const editedUser = {
      id: user.id,
      isEdited: true,
      initialValue: user.name,
    };
    setEditUser((prev) => ({
      ...prev,
      ...editedUser,
    }));
  };

  const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueToChange = e.target.value;

    setEditUser((prevState: EditUserType) => ({
      ...prevState,
      editValue: valueToChange,
    }));
  };

  const handleEditUserName = async (user: databaseAddressBookType) => {
    const obj = {
      id: user.id,
      address: user.address,
      name: editUser.editValue,
    };
    try {
      if (databaseIsReady) {
        await db?.editObject(config.DB_STORE_ADDRESS_BOOK, obj);
        setEditUser(initialEditUserState);
        getAddressBook();
      }
    } catch (error) {
      toast.error("Failed to fetch address book, try again later.");
    }
  };

  const handlePressEnter = (
    e: React.KeyboardEvent,
    user: databaseAddressBookType
  ) => {
    if (e.key === "Enter") {
      handleEditUserName(user);
    }
  };

  return (
    <div className={s.listInner}>
      {Object.keys(sortedUsers).map((key) => (
        <div key={key} className={s.listWrapper}>
          <span className={s.startLetter}>{key}</span>
          <ul className={s.list}>
            {sortedUsers[key].map((user: databaseAddressBookType) => (
              <AddressBookListItem
                key={user.id}
                user={user}
                withModal
                handleEditUser={handleEditUser}
                editUser={editUser}
                handleChangeUserName={handleChangeUserName}
                handleEditUserName={handleEditUserName}
                handlePressEnter={handlePressEnter}
                handleRemoveUser={handleRemoveUser}
                handleCloseModal={handleCloseModal}
                handleOpenModal={handleOpenModal}
                getAddressBook={getAddressBook}
                openModal={openModal}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
