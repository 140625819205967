import React, { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { QRCodeSVG } from "qrcode.react";
import { Helmet } from "react-helmet-async";
import { FormInput } from "components/FormInput/FormInput";
import "./Receive.scss";
import { Button } from "components/Button/Button";
import { useAppSelector } from "../../redux";

export const Receive = () => {
  const { walletAddresses } = useAppSelector((state: RootState) => state.auth);
  const isDarkMode = useAppSelector((state) => state.ui.theme) === "dark";
  const [selectedWallet, setSelectedWallet] = useState(
    walletAddresses[0].address
  );

  const shareButtonContent = useCallback(() => {
    if (isMobile) {
      return (
        <a
          href={`sms://&body=My%20wallet%20address:%20${selectedWallet}`}
          style={{ textDecoration: "none" }}
        >
          <Button size="small" variant="primary" type="button">
            Share
          </Button>
        </a>
      );
    }
    return (
      <a
        href={`mailto:?subject=My wallet address&body=Wallet address: ${selectedWallet}`}
        style={{ textDecoration: "none" }}
      >
        <Button size="small" variant="primary" type="button">
          Share
        </Button>
      </a>
    );
  }, [selectedWallet, walletAddresses]);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Receive</title>
      </Helmet>
      <div className="Receive">
        <p className="title">
          Select Fork and scan this code to receive the payment.
        </p>
        <div className="selectAddress">
          <FormInput
            label="Selected fork"
            variant="customSelect"
            type="select"
            placeholder=""
            value={selectedWallet.address}
            customOnChange={(e) => setSelectedWallet(e.target.value)}
            customOptions={walletAddresses}
          />
        </div>
        <div className="frame">
          <div className="qr-code">
            <QRCodeSVG
              value={selectedWallet}
              bgColor={isDarkMode ? "#2c3034" : "#008ba3"}
              fgColor={isDarkMode ? "#008ba3" : "#ffffff"}
            />
          </div>
        </div>
        <div className="buttons">{shareButtonContent()}</div>
      </div>
    </>
  );
};
