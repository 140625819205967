/* eslint-disable */
import { useState, useEffect } from "react";
import cx from "classnames";
import { useFormContext, Controller } from "react-hook-form";
import { useAppSelector } from "../../redux";
import { FormInput } from "components/FormInput/FormInput";
import { ReactComponent as Arrow } from "./img/arrow.svg";
import s from "./TransactionsFilterDesktop.module.scss";
import { ErrorMessage } from "@hookform/error-message";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayjs from "dayjs";
import { FilterPropType } from "./Transactions";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type Tab = {
  id: number;
  name: string;
  type: string;
  tabType?: string;
};

type TokensSelectType = {
  name: string;
  value: string;
};

type TransactionsFilterDesktop = {
  filterTabs: Tab[];
  handleFilters: any;
  activeFilters: FilterPropType;
};

export const TransactionsFilterDesktop = ({
  filterTabs,
  handleFilters,
}: TransactionsFilterDesktop) => {
  const onlyFilterTabs = filterTabs.filter((tab) => tab.type === "filter");
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const [activeTabs, setActiveTabs] = useState<any>({
    date: false,
    amount: false,
    coin: false,
    status: false,
  });
  const tokensInfo = useAppSelector((state) => state.wallet?.tokensInfo);
  const [tokensSelectOptions, setTokensSelectOptions] = useState<
    TokensSelectType[]
  >([]);

  useEffect(() => {
    if (tokensSelectOptions.length < 3) {
      setTokensSelectOptions([{ name: "all", value: "all" }]);

      const coinsArray = tokensInfo.map((el: any) => ({
        name: el.name,
        value: el.name,
      }));

      setTokensSelectOptions((prev) => [...prev, ...coinsArray]);

      if (tokensInfo.length > 0) setValue("coin", "all");
    }
  }, []);

  const handleToggle = (tabName: any) => {
    setActiveTabs((prevState: any) => ({
      ...prevState,
      [tabName]: !prevState[tabName],
    }));
  };

  const onHandleSubmit = (data: any) => {
    handleFilters(data);
  };
  return (
    <div className={s.filter}>
      <div className={s.inner}>
        {onlyFilterTabs.map((tab) => (
          <div key={tab.id} className={s.tab}>
            <button
              onClick={() => handleToggle(tab.tabType)}
              className={s.tabTop}
            >
              <span className={s.name}>{tab.name}</span>
              <span
                className={cx(
                  s.arrowIcon,
                  activeTabs[`${tab.tabType}`] ? s.active : ""
                )}
              >
                <Arrow />
              </span>
            </button>
            {tab.tabType === "date" && (
              <div
                className={cx(
                  s.tabBody,
                  activeTabs[`${tab.tabType}`] ? s.active : ""
                )}
              >
                <div
                  className={cx(
                    s.tabInner,
                    activeTabs[`${tab.tabType}`] ? s.active : ""
                  )}
                >
                  <div className={s.inputWrapper}>
                    <FormInput
                      label="From"
                      variant="date"
                      type="date"
                      placeholder=""
                      {...register("dateFrom")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dateFrom"
                      message="Date from must be earlier than date to"
                    />
                  </div>
                  <div className={s.inputWrapper}>
                    <FormInput
                      label="To"
                      variant="date"
                      type="date"
                      placeholder=""
                      {...register("dateTo")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dateTo"
                      message="Choose date"
                    />
                  </div>
                </div>
              </div>
            )}
            {tab.tabType === "amount" && (
              <div
                className={cx(
                  s.tabBody,
                  activeTabs[`${tab.tabType}`] ? s.active : ""
                )}
              >
                <div
                  className={cx(
                    s.tabInner,
                    activeTabs[`${tab.tabType}`] ? s.active : ""
                  )}
                >
                  <div className={s.inputWrapper}>
                    <FormInput
                      label="Min amount"
                      variant="text"
                      type="number"
                      placeholder="Min amount"
                      {...register("minAmount")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="minAmount"
                      message="Fill amount"
                    />
                  </div>
                  <div className={s.inputWrapper}>
                    <FormInput
                      label="Max amount"
                      variant="text"
                      type="number"
                      placeholder="Max amount"
                      {...register("maxAmount")}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="maxAmount"
                      message="Fill amount"
                    />
                  </div>
                </div>
              </div>
            )}
            {tab.tabType === "coin" && (
              <div
                className={cx(
                  s.tabBody,
                  activeTabs[`${tab.tabType}`] ? s.active : ""
                )}
              >
                <div
                  className={cx(
                    s.tabInner,
                    activeTabs[`${tab.tabType}`] ? s.active : ""
                  )}
                >
                  <div className={s.inputWrapper}>
                    <Controller
                      name="coin"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormInput
                          label="Choose Token"
                          variant="select"
                          type="select"
                          placeholder=""
                          value={value ? value : ""}
                          onChange={(e) => onChange(e)}
                          options={tokensSelectOptions}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {tab.tabType === "status" && (
              <div
                className={cx(
                  s.tabBody,
                  activeTabs[`${tab.tabType}`] ? s.active : ""
                )}
              >
                <div
                  className={cx(
                    s.tabInner,
                    activeTabs[`${tab.tabType}`] ? s.active : ""
                  )}
                >
                  <div className={cx(s.inputWrapper, s.checkboxWrapper)}>
                    <Checkbox
                      onChange={async (e) => {
                        await console.log("change");
                      }}
                      ref={null}
                      onBlur={async (e) => {
                        await console.log("change");
                      }}
                      name="send"
                      errors={{}}
                    >
                      Send
                    </Checkbox>
                    <Checkbox
                      onChange={async (e) => {
                        await console.log("change");
                      }}
                      ref={null}
                      onBlur={async (e) => {
                        await console.log("change");
                      }}
                      name="Received"
                      errors={{}}
                    >
                      Received
                    </Checkbox>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        ))}
      </div>

      <div className={s.actionBox}>
        <button
          className={s.applyFilter}
          type="submit"
          onClick={handleSubmit(onHandleSubmit)}
        >
          Apply filters
        </button>
      </div>
    </div>
  );
};
