import { ethers } from "ethers";
import { createAsyncThunk, createSlice, Slice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import { api } from "../../../api/config";

interface ApiResponse {
  balances: Balances[];
  rates: Rates[];
  forks: Forks[];
}

export const getTokensInfo = createAsyncThunk(
  "wallet/getTokensInfo",
  async (puzzleHash: PuzzleHash[]) => {
    const [b, r, f] = await Promise.all([
      await api.post("/fork/balances", puzzleHash),
      await api.get("/fork/rates"),
      await api.get("/fork/all"),
    ]);

    const data = {
      balances: b.data.data,
      rates: r.data.rates,
      forks: f.data.data,
    } as ApiResponse;

    const { balances, rates, forks } = data;

    const tokensInfo: TokensInfo[] = [];
    let theBiggestDecimal = 2;

    balances.forEach((item: Balances, index: number) => {
      const thisRate = rates.find(
        (o: Rates) => o.name.toLowerCase() === item.fork.toLowerCase()
      );
      const thisFork = forks.find(
        (el: Forks) => el.name.toLowerCase() === item.fork.toLowerCase()
      );

      if (Number(thisFork?.decimals) > theBiggestDecimal) {
        theBiggestDecimal = Number(thisFork?.decimals);
      }

      const balanceDecimals = ethers.utils.formatUnits(
        item.balance,
        thisFork?.decimals
      );
      const balanceBig = new BigNumber(Number(balanceDecimals));
      const rate = Number(thisRate?.price);
      const usdBalance = balanceBig
        .multipliedBy(rate)
        .toFixed(thisFork?.decimals ? thisFork.decimals : 0);

      tokensInfo.push({
        id: index,
        name: item.fork,
        balance: item.balance,
        currency: item.fork,
        usdValue: usdBalance,
        decimals: thisFork?.decimals,
        symbol: thisFork?.nativeCoin,
        growth24: 1.5,
        usdPrice: Number(thisRate?.price),
        logoSrc: thisFork?.logo,
        derivationPath: thisFork?.derivationPath,
        percentage: 0,
        details: {
          rates_addresses: thisRate?.address,
          rates_farmed: thisRate?.farmed,
          rates_height: thisRate?.height,
          rates_marketcap: thisRate?.marketcap,
          rates_netspace: thisRate?.netspace,
          rates_prefarmed: thisRate?.prefarmed,
          rates_pricebtc: thisRate?.pricebtc,
          rates_priceeth: thisRate?.priceeth,
          rates_priceexch: thisRate?.pricexch,
          rates_rank: thisRate?.rank,
          rates_symbol: thisRate?.symbol,
          rates_update: thisRate?.update,
        },
      });
    });

    const totalUsdBalance = tokensInfo.reduce(
      (acc, obj) => acc + Number(obj.usdValue),
      0
    );

    const totalUsdBalanceString = totalUsdBalance.toFixed(theBiggestDecimal);

    tokensInfo.forEach((item: TokensInfo, index: number) => {
      if (item.usdValue && totalUsdBalance) {
        tokensInfo[index].percentage = Number(
          ((Number(item.usdValue) / Number(totalUsdBalance)) * 100).toFixed(2)
        );
      }
    });

    return {
      tokensInfo,
      totalUsdBalanceString,
    };
  }
);

interface WalletState {
  tokensInfo: TokensInfo[];
  totalUsdBalance: string;
  error: string;
  isLoading: boolean;
}

const initialState: WalletState = {
  tokensInfo: [],
  totalUsdBalance: "",
  error: "",
  isLoading: false,
};

export const walletSlice: Slice<WalletState> = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTokensInfo.pending, (state: WalletState) => {
      state.isLoading = true;
    });
    builder.addCase(
      getTokensInfo.fulfilled,
      (state: WalletState, { payload }) => {
        const { tokensInfo, totalUsdBalanceString } = { ...payload };
        state.isLoading = false;
        state.tokensInfo = tokensInfo;
        state.totalUsdBalance = totalUsdBalanceString;
      }
    );
    builder.addCase(getTokensInfo.rejected, (state: WalletState) => {
      state.isLoading = false;
      toast.error("The token information could not be retrieved");
    });
  },
});
