/* eslint-disable */
import { DotsButton } from "components/DotsButton/DotsButton";
import React, { MutableRefObject } from "react";
import { ListModal } from "components";
import s from "./AddressBookListItem.module.scss";
import { ReactComponent as DeleteIcon } from "./img/delete.svg";
import { ReactComponent as EditIcon } from "./img/edit.svg";
import { ReactComponent as BackIcon } from "./img/back.svg";
import { ReactComponent as TickIcon } from "./img/tick.svg";
import { UserDataType } from "./AddressBook";
import { EditUserType, ModalState } from "./AddressBookList";
import { useAppDispatch } from "../../redux";
import { showDrawer } from "../../redux/features/ui/uiSlice";
import { GenericObjectID } from "../../helpers";

type AddressBookListItem = {
  user: databaseAddressBookType;
  withModal?: boolean;
  handleEditUser: any;
  editUser: EditUserType;
  handleRemoveUser: (id: GenericObjectID) => void;
  handleChangeUserName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditUserName: (user: databaseAddressBookType) => void;
  handlePressEnter: (
    e: React.KeyboardEvent,
    user: databaseAddressBookType
  ) => void;
  handleOpenModal: (user: databaseAddressBookType) => void;
  handleCloseModal: () => void;
  openModal: ModalState;
  getAddressBook: () => void;
  modalRef?: React.ForwardedRef<HTMLDivElement>;
};

export const AddressBookListItem = React.forwardRef<
  HTMLDivElement,
  AddressBookListItem
>(
  ({
    user,
    withModal,
    handleEditUser,
    editUser,
    handleChangeUserName,
    handleEditUserName,
    handlePressEnter,
    handleRemoveUser,
    openModal,
    handleOpenModal,
    handleCloseModal,
    getAddressBook,
    modalRef,
  }) => {
    const showModal = openModal.id === user.id && openModal.open === true;
    const dispatch = useAppDispatch();

    return (
      <li key={user.id} className={s.listItem}>
        <div className={s.avatar}>
          <span className={s.avatarLetter}>{user.name[0]}</span>
        </div>
        <div className={s.body}>
          <div className={s.content}>
            {user.id === editUser.id ? (
              <input
                defaultValue={user.name}
                className={s.editInput}
                type="text"
                onChange={handleChangeUserName}
                onKeyUp={(e) => handlePressEnter(e, user)}
              />
            ) : (
              <span className={s.name}>{user.name}</span>
            )}

            <span className={s.id}>{user.address}</span>
          </div>
          {user.id === editUser.id ? (
            <div className={s.editWrapper}>
              <span
                onClick={() => handleEditUserName(user)}
                className={s.editIcon}
              >
                <BackIcon />
              </span>
              <span
                onClick={() => handleEditUserName(user)}
                className={s.editIcon}
              >
                <TickIcon />
              </span>
            </div>
          ) : (
            <DotsButton
              active={showModal}
              onClick={() => {
                handleOpenModal(user);
              }}
            />
          )}
        </div>

        {withModal && (
          <ListModal showModal={showModal} modalRef={modalRef}>
            <div className={s.inner}>
              <div
                onClick={() => {
                  handleCloseModal();
                  handleEditUser(user);
                }}
                className={s.optionWrapper}
              >
                <div className={s.iconWrapper}>
                  <EditIcon />
                </div>
                <span className={s.option}>Change Name</span>
              </div>
              <div
                onClick={() =>
                  dispatch(
                    showDrawer("address-book-remove", {
                      onClick: () => handleRemoveUser(user.id),
                    })
                  )
                }
                className={s.optionWrapper}
              >
                <div className={s.iconWrapper}>
                  <DeleteIcon />
                </div>
                <span className={s.option}>Delete</span>
              </div>
            </div>
          </ListModal>
        )}
      </li>
    );
  }
);
