import { Navigate } from "react-router-dom";
import { PATHS } from "../../constants";
import { useAppSelector } from "../../redux";

type ComponentType = {
  Component: () => JSX.Element;
};

export const PrivateRoute = ({ Component }: ComponentType) => {
  const { isAuth, wallet } = useAppSelector((state: RootState) => state.auth);

  return isAuth && wallet ? <Component /> : <Navigate to={PATHS.WELCOME} />;
};
