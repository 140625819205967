import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "components";
import { ReactComponent as CopySvg } from "../ButtonCopyToClipboard/img/copy.svg";
import { FormHeader } from "./FormHeader";
import s from "./FirstStep.module.scss";

interface FirstStepProps {
  handleNextStep: () => void;
  generateMnemonic: () => void;
  mnemonicWords: string;
  textToDisplay: string;
}

export const FirstStep = ({
  handleNextStep,
  generateMnemonic,
  mnemonicWords,
  textToDisplay,
}: FirstStepProps) => {
  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  const handleCopy = (copyText: string) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(() => {
        toast.error("Copy to clipboard failed.");
      });
  };

  return (
    <>
      <FormHeader
        title="Secret Backup Phrase"
        desc="This is your seed phrase. Write it down on a paper and keep it in a safe place. You'll be asked to re-enter this phrase (in order) on the next step."
      />
      <div className={s.formWrapper}>
        <div
          onClick={!mnemonicWords ? generateMnemonic : undefined}
          className={s.board}
          style={{ cursor: !mnemonicWords ? "pointer" : "default" }}
        >
          {mnemonicWords ? (
            <div className={s.boardDesc}>{textToDisplay}</div>
          ) : (
            <div className={s.boardDesc}>Click here to reveal secret words</div>
          )}
        </div>
        {mnemonicWords ? (
          <Button
            type="button"
            size="small"
            variant="primary"
            onClick={() => handleCopy(mnemonicWords)}
            fullWidth
            className={s.copyBtn}
          >
            {isCopied ? "mnemonic copied!" : "Copy mnemonic"}
            {isCopied ? null : <CopySvg />}
          </Button>
        ) : null}
        {mnemonicWords ? (
          <Button
            type="button"
            size="small"
            variant="secondary"
            onClick={generateMnemonic}
            fullWidth
          >
            Generate new phrase
          </Button>
        ) : null}
        <div className={s.body}>
          <p className={s.desc}>
            <strong>WARNING:</strong>{" "}
            {`Never disclose your backup phrase. If
          someone gets your seed phrase, they'll gain control over your account.`}
          </p>
          <p className={s.desc}>
            Write this phrase on a pice of paper and store in a secure location.
            If you want even more security, write it down on multiple pieces of
            paper and store each in 2-3 different locations.
          </p>
          <p className={s.desc}>
            Memorize this phrase. If you lose it you will not be able to access
            your wallet.
          </p>
          <p className={s.desc}>
            <strong>
              Dowload this Secret Backup Phrase and keep it stored safely on an
              external encrypted hard drive or storage medium.
            </strong>
          </p>
        </div>
        <div className={s.ctaGroup}>
          <Button
            disabled={!mnemonicWords}
            size="small"
            variant="primary"
            onClick={handleNextStep}
            type="button"
            fullWidth
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
