import type { Wallet } from "@nextrope/chia-wallet-sdk";
import * as greenweb from "greenwebjs";
import { api } from "../api/config";

export const getHashesHelper = async (password: string, wallet: Wallet) => {
  await greenweb.clvm.initialize();
  const forks = await api.get("/fork/all");
  const { data } = forks;

  const getHash = data.data.map(async (item: Forks) => ({
    fork: item.name[0].toUpperCase() + item.name.slice(1),
    puzzleHash: await wallet.puzzleHash(password),
  }));

  const hashes = await Promise.all(getHash);
  return hashes;
};
