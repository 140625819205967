/* eslint-disable */
import React, { LegacyRef, SyntheticEvent } from "react";
import "./FormInput.scss";
import cx from "classnames";
import passwordIcon from "./img/password.svg";
import passwordIconShow from "./img/password-show.svg";

import { ReactComponent as Arrow } from "./img/arrow.svg";

type OptionsType = {
  name: string;
  value: string;
};

type CustomOptionsType = {
  name: string;
  address: string;
};

type FormInputProps = {
  label?: string;
  name?: string;
  placeholder: string;
  type?: string;
  onChange?: (e: SyntheticEvent) => void;
  children?: React.ReactNode;
  desc?: string;
  inputRef?: any;
  onBlur?: any;
  errors?: any;
  onClick?: any;
  variant:
    | "password"
    | "text"
    | "date"
    | "select"
    | "select-currency"
    | "customSelect";
  options?: OptionsType[];
  customOptions?: CustomOptionsType[];
  customOnChange?: React.ChangeEventHandler<HTMLSelectElement>;
  value?: string;
};

export const FormInput = React.forwardRef<
  HTMLInputElement | any,
  FormInputProps
>(
  (
    {
      label,
      name,
      onClick,
      errors,
      placeholder,
      type,
      onChange,
      desc,
      onBlur,
      variant,
      options,
      value,
      customOptions,
      customOnChange,
    },
    ref
  ) => {
    return (
      <div className={"formInputWrapper"}>
        {label ? (
          <div className={"labelWrapper"}>
            <label className={"label"} htmlFor={name}>
              {label}
            </label>
            {desc ? <span className={"labelDesc"}>{desc}</span> : null}
          </div>
        ) : null}
        {variant === "text" && (
          <div className={"inputWrapper"}>
            <input
              className={"input"}
              name={name}
              placeholder={placeholder}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              id={name}
              type={type}
              step={type === "number" ? "any" : undefined}
            />
          </div>
        )}
        {variant === "password" && (
          <div className={"inputWrapper"}>
            <input
              className={"input"}
              name={name}
              placeholder={placeholder}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              id={name}
              type={type}
            />
            <div onClick={onClick} className={"iconWrapper"}>
              <img
                style={{ width: "16px", height: "12.3px" }}
                className={"icon"}
                src={type === "password" ? passwordIconShow : passwordIcon}
                alt={name}
              />
            </div>
          </div>
        )}
        {variant === "date" && (
          <div className={"inputWrapper"}>
            <input
              className={"input"}
              name={name}
              placeholder={placeholder}
              ref={ref}
              onChange={onChange}
              onBlur={onBlur}
              id={name}
              type={type}
            />
          </div>
        )}
        {variant === "select" && (
          <div className={"selectWrapper"}>
            <span className={"icon"}>
              <Arrow />
            </span>
            <select
              className={"select"}
              ref={ref}
              onChange={onChange}
              value={value}
              defaultValue={value}
            >
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {variant === "customSelect" && (
          <div className={"selectWrapper"}>
            <span className={"icon"}>
              <Arrow />
            </span>
            <select
              className={"select"}
              ref={ref}
              onChange={customOnChange ? customOnChange : onChange}
              value={value}
              defaultValue={value}
            >
              {customOptions?.map((option) => (
                <option key={option.name} value={option.address}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {variant === "select-currency" && (
          <div className={cx("selectWrapper", "currency")}>
            <span className={"icon"}>
              <Arrow />
            </span>
            <select
              className={"select"}
              ref={ref}
              onChange={onChange}
              value={value}
            >
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  <span>{option.name}</span>
                </option>
              ))}
            </select>
          </div>
        )}
        {errors && errors[`${name}`] ? (
          <span className={"error"}>{errors[`${name}`].message}</span>
        ) : null}
      </div>
    );
  }
);
