import {
  Home,
  HomeAddAssets,
  HomeDetails,
  Create,
  Import,
  Login,
  Portfolio,
  Transactions,
  Settings,
  Send,
  Receive,
  AddressBook,
} from "../pages";
import Welcome from "../pages/Login/Welcome";

export enum PATHS {
  WELCOME = "/welcome",
  LOGIN = "/login",
  LOGIN_WELCOME = "/login-welcome",
  CREATE = "/create",
  IMPORT = "/import",
  HOME = "/dashboard",
  HOME_ADD_ASSETS = "/dashboard/add-assets",
  HOME_DETAILS = "/dashboard/details/:coinId",
  PORTFOLIO = "/portfolio",
  TRANSACTIONS = "/transactions",
  SETTINGS = "/settings",
  SEND = "/send",
  RECEIVE = "/receive",
  ADDRESS_BOOK = "/address-book",
}

export const ROUTES = [
  {
    name: "Login",
    path: PATHS.LOGIN,
    view: Login,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: false,
  },
  {
    name: "Login",
    path: PATHS.LOGIN_WELCOME,
    view: Login,
    isPrivate: false,
  },
  {
    name: "Welcome",
    path: PATHS.WELCOME,
    view: Welcome,
    isNavigation: true,
  },
  {
    name: "Create",
    path: PATHS.CREATE,
    view: Create,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: false,
  },
  {
    name: "Import",
    path: PATHS.IMPORT,
    view: Import,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: false,
  },
  {
    name: "Home",
    path: PATHS.HOME,
    view: Home,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "Transactions",
    path: PATHS.TRANSACTIONS,
    view: Transactions,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "Settings",
    path: PATHS.SETTINGS,
    view: Settings,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "Portfolio",
    path: PATHS.PORTFOLIO,
    view: Portfolio,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "HomeAddAssets",
    path: PATHS.HOME_ADD_ASSETS,
    view: HomeAddAssets,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "HomeDetails",
    path: PATHS.HOME_DETAILS,
    view: HomeDetails,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "Send",
    path: PATHS.SEND,
    view: Send,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "Receive",
    path: PATHS.RECEIVE,
    view: Receive,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },
  {
    name: "AddressBook",
    path: PATHS.ADDRESS_BOOK,
    view: AddressBook,
    // icon: HomeIcon,
    isNavigation: true,
    isPrivate: true,
  },

  // {
  //   name: "Forks",
  //   path: "/forks",
  //   view: Forks,
  //   icon: ForksIcon,
  //   isNavigation: true,
  // },
  // {
  //   name: "Settings",
  //   path: "/settings",
  //   view: Settings,
  //   icon: SettingsIcon,
  //   isNavigation: true,
  // },
  // {
  //   name: "Privacy Policy",
  //   path: "/settings/privacy-policy",
  //   view: PrivacyPolicy,
  //   icon: null,
  //   isNavigation: false,
  // },
  // {
  //   name: "Contact Us",
  //   path: "/settings/contact",
  //   view: ContactUs,
  //   icon: null,
  //   isNavigation: false,
  // },
  // {
  //   name: "Notifications",
  //   path: "/settings/notifications",
  //   view: Notification,
  //   icon: null,
  //   isNavigation: false,
  // },
];

export const NAV_ROUTES = ROUTES.filter(({ isNavigation }) => isNavigation);
