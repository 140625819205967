/* eslint-disable */

import cx from "classnames";
import s from "./DotsButton.module.scss";

interface DotsButtonProps {
  active: boolean;
  onClick: () => void;
}

export const DotsButton = ({ active, onClick }: DotsButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(s.button, active ? s.active : null)}
  >
    <span className={s.dot} />
    <span className={s.dot} />
    <span className={s.dot} />
  </button>
);
