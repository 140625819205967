import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { BackButton } from "components/BackButton/BackButton";
import { Button } from "components/Button/Button";
import s from "./AddressBook.module.scss";
import { AddressBookList } from "./AddressBookList";
import { ReactComponent as SearchIcon } from "./img/search.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as AddressBookIcon } from "../../assets/icons/address-book.svg";
import { useAppDispatch, useDatabaseContext } from "../../redux";
import { showDrawer } from "../../redux/features/ui/uiSlice";
import { config } from "../../config";

export type UserDataType = {
  name: string;
  id: string;
};

export const AddressBook = () => {
  const { db, databaseIsReady } = useDatabaseContext();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [dataFetch, setDataFetch] = useState<databaseAddressBookType[]>([]);

  const filterUserData = [
    ...dataFetch.filter((user) =>
      user.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    ),
  ];

  const handleGetAddressBook = () => {
    if (databaseIsReady) {
      db?.getAllObjects(config.DB_STORE_ADDRESS_BOOK, setDataFetch);
    }
  };

  const handleShowAddRecordDrawer = () => {
    dispatch(
      showDrawer("address-book-add", {
        onSuccess: () => handleGetAddressBook(),
      })
    );
  };

  useEffect(() => {
    handleGetAddressBook();
  }, []);

  const areThereSavedAddresses = filterUserData.length > 0;

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Address Book</title>
      </Helmet>
      <section className={s.address}>
        <div className={s.wrapper}>
          <BackButton />
          {areThereSavedAddresses && (
            <>
              <Button
                size="small"
                variant="primary"
                type="button"
                onClick={handleShowAddRecordDrawer}
              >
                Add Record
              </Button>
              <header className={s.header}>
                <div className={s.searchWrapper}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="search">
                    <span className={s.searchIconWrapper}>
                      <SearchIcon />
                    </span>
                  </label>
                  <input
                    name="search"
                    id="search"
                    className={s.input}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search"
                  />
                </div>
              </header>
            </>
          )}
          {areThereSavedAddresses ? (
            <div className={s.listContainer}>
              <AddressBookList
                userData={filterUserData}
                getAddressBook={() => handleGetAddressBook()}
              />
            </div>
          ) : (
            <div className={s.noDataPlaceholder}>
              <AddressBookIcon />
              <p>
                Currently you don’t have any saved addresses in your address
                book.
              </p>
              <button
                className={s.noDataPlaceholder__button}
                onClick={handleShowAddRecordDrawer}
              >
                Import Addresses <RightArrow />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
