import { sortUserAlphabetically } from "helpers/sortUserAlphabetically";
import { groupedByFirstCharacter } from "helpers/groupByFirstCharacter";
import s from "./SendAddressBookList.module.scss";
import { SendAddressBookListItem } from "./SendAddressBookListItem";

type AddressBookListProps = {
  userData: databaseAddressBookType[];
  setRecipient: (address: string) => void;
};

export const SendAddressBook = ({
  userData,
  setRecipient,
}: AddressBookListProps) => {
  const sortedUsers = groupedByFirstCharacter(sortUserAlphabetically(userData));

  return (
    <div className={s.listInner}>
      {Object.keys(sortedUsers).map((key) => (
        <div key={key} className={s.listWrapper}>
          <ul className={s.list}>
            {sortedUsers[key].map((user: databaseAddressBookType) => (
              <SendAddressBookListItem
                onSelect={setRecipient}
                key={user.id}
                user={user}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
