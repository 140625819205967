/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as StarIconWithoutBg } from "./img/starWithoutBg.svg";
import { sortUserAlphabetically } from "helpers/sortUserAlphabetically";
import s from "./Home.module.scss";
import { ReactComponent as RightArrow } from "./img/right-arrow.svg";
import { HomeTile } from "./HomeTile";
import { HomeTileChia } from "./HomeTileChia";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getTokensInfo } from "redux/features/wallet/walletSlice";
import { typeguardFavouriteCoins } from "helpers";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { apiStakingApp } from "../../api/config";
import { toast } from "react-toastify";

export const Home = () => {
  const [stakingGains, setStakingGains] = useState("");
  const [isLoadingFetchingStakingGains, setIsLoadingFetchingStakingGains] =
    useState(false);
  const dispatch = useAppDispatch();
  const { tokensInfo } = useAppSelector((state: RootState) => state.wallet);
  const { hashes } = useAppSelector((state: RootState) => state.auth);
  const { walletAddress } = useAppSelector((state) => state.auth);
  const [favouriteIcon, setFavouriteIcon] = useState<any[]>([]);
  useEffect(() => {
    // @ts-ignore: Unreachable code error
    dispatch(getTokensInfo(hashes));
  }, []);

  useEffect(() => {
    const uknownFavouriteCoin = localStorage.getItem("favouriteCoins");
    let favouriteCoins = typeguardFavouriteCoins(uknownFavouriteCoin);
    let array: any[] = [];
    tokensInfo.forEach((element: any) => {
      if (favouriteCoins.includes(element?.id)) array.push(element);
    });
    setFavouriteIcon(array);
  }, []);
  const chainCoin = useMemo(() => {
    return tokensInfo
      .filter((el: any) => el.name === "chia")
      .map((coin: any) => ({ ...coin }))[0];
  }, [tokensInfo]);

  const handleFetchStakingGains = async (walletAddress: string) => {
    setIsLoadingFetchingStakingGains(true);
    try {
      const response = await apiStakingApp.get(
        `/staking-gains/total-by-wallet/${walletAddress}`
      );
      setStakingGains(response.data.total);
      setIsLoadingFetchingStakingGains(false);
    } catch (error) {
      toast.error("We were unable to fetch staking gains");
      setIsLoadingFetchingStakingGains(false);
    }
  };

  useEffect(() => {
    handleFetchStakingGains(walletAddress);
  }, []);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Home</title>
      </Helmet>
      <section className={s.home}>
        <header className={s.header}>
          <div>Assets</div>
        </header>
        <div className={s.mainWrapper}>
          <div className={s.chiaCoin}>
            {chainCoin && (
              <HomeTileChia
                stakingGains={stakingGains}
                stakingGainsPending={isLoadingFetchingStakingGains}
                chiaCoin={chainCoin}
              />
            )}
          </div>
          <div className={s.coinsWrapper}>
            {favouriteIcon.length > 0 ? (
              sortUserAlphabetically(favouriteIcon).map((coin: any) => (
                <div key={coin.id} className={s.coinContainer}>
                  <HomeTile variant="dashboard" coin={coin} />
                </div>
              ))
            ) : (
              <div className={s.favouritePlaceholder}>
                <StarIconWithoutBg className="starIcon" />
                <p>Currenty you don’t have any assets in your favorite list.</p>
                <Link className={s.linkIcon} to="/dashboard/add-assets">
                  Manage Assets
                  <RightArrow />
                </Link>
              </div>
            )}
            {favouriteIcon.length > 0 && (
              <div className={s.linkIconWithTilesWrapper}>
                <Link to="/dashboard/add-assets">
                  Manage Assets
                  <RightArrow />
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
