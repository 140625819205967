import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, FormInput } from "components";
import s from "./Welcome.module.scss";
import { PATHS } from "../../constants";
import { useAppSelector, useAppDispatch } from "../../redux";
import { handleLogin } from "../../redux/features/auth/authSlice";

type TFormValues = {
  password: string;
};

export const Login = () => {
  const [passwordInputType, setPasswordInputType] = useState("password");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAuth = useAppSelector((state) => state.auth?.isAuth);
  const navigate = useNavigate();
  const {
    keystore: serializedKeystore,
    isLoading,
    error,
  } = useAppSelector((state: RootState) => state.auth);

  const isWelcome = location.pathname === "/login-welcome";
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<TFormValues>();

  const handleOnSubmit = async (data: TFormValues) => {
    const { password } = data;
    const keystore = serializedKeystore as string;
    await dispatch(handleLogin({ password, keystore }));
    if (error)
      setError(
        "password",
        { type: "custom", message: error },
        { shouldFocus: true }
      );
  };

  useEffect(() => {
    if (error)
      setError(
        "password",
        { type: "custom", message: error },
        { shouldFocus: true }
      );
  }, [error]);

  useEffect(() => {
    if (isAuth) {
      navigate(PATHS.HOME);
    }
  }, [isAuth]);

  useEffect(() => {
    if (!localStorage.getItem("keystore")) {
      navigate(PATHS.WELCOME);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Login</title>
      </Helmet>
      <section>
        <div className={s.content}>
          <h1 className={s.titleLogin}>
            {isWelcome ? "Welcome Back" : "Log In"}
          </h1>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className={s.formWrapper}>
              <div className={s.inputWrapper}>
                <FormInput
                  variant="password"
                  placeholder=" "
                  type={passwordInputType}
                  label="Password"
                  {...register("password", {
                    required: "You must provide a password",
                  })}
                  errors={errors}
                  onClick={() =>
                    setPasswordInputType((prevState) =>
                      prevState === "password" ? "text" : "password"
                    )
                  }
                />
              </div>
              <div className={s.ctaWrapper}>
                <Button
                  type="submit"
                  size="big"
                  variant="primary"
                  isLoading={isLoading}
                >
                  Log In
                </Button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
