/* eslint-disable */
export const groupedByFirstCharacter = (arr: any) =>
  arr.reduce((accumulator: any, currentValue: any) => {
    const firstLetter = currentValue.name[0];
    // eslint-disable-next-line no-prototype-builtins
    if (accumulator.hasOwnProperty(firstLetter)) {
      const newArray = [...accumulator[firstLetter], currentValue];
      return { ...accumulator, [firstLetter]: newArray };
    }
    return {
      ...accumulator,
      [firstLetter]: [currentValue],
    };
  }, {});
