import React from "react";
import s from "components/UnderlineButton/UnderlineButton.module.scss";
import { ReactComponent as ArrowIcon } from "./img/arrow.svg";

type UnderlineButtonType = {
  children: React.ReactNode;
  onClick?: () => void;
  icon?: boolean;
};

export const UnderlineButton = ({
  onClick,
  icon,
  children,
}: UnderlineButtonType) => (
  <div className={s.buttonWrapper}>
    <button className={s.button} onClick={onClick} type="button">
      <span className={s.copy}>{children}</span>
      {icon && (
        <span className={s.icon}>
          <ArrowIcon />
        </span>
      )}
    </button>
  </div>
);
