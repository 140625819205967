/* eslint-disable */
import React, { useState } from "react";
import "./ButtonCopyToClipboard.scss";
import { toast } from "react-toastify";
import CopyImg from "./img/copy.svg";

interface Props {
  copyText: string;
  name: string;
}

export const ButtonCopyToClipboard: React.FC<Props> = ({ name, copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return document.execCommand("copy", true, text);
  }

  const handleCopy = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        toast.error("Copy to clipboard failed.");
      });
  };

  return (
    <div className="ButtonCopyToClipboard">
      <div className="ButtonCopyToClipboard-text">
        <span className="bold">{name} - </span>
        <span>{copyText}</span>
      </div>
      <img
        onClick={() => {
          handleCopy();
        }}
        src={CopyImg}
        alt="copy icon"
      />
      <div className={isCopied ? "copy-info active" : "copy-info"}>
        Address copied to clipboard
      </div>
    </div>
  );
};
