/* eslint-disable */
import React, { useRef } from "react";
import cx from "classnames";
import { ReactComponent as ConvertIcon } from "./img/convert.svg";
import "./CurrencyConverter.scss";
import { UseFormReturn } from "react-hook-form";

type CurrencyConverterProps = {
  id: string;
  size?: "big";
  convertTo: { value: number | string; currency: string };
  convertFrom: { value: number | string; currency: string };
};

export const CurrencyConverter = React.forwardRef<
  HTMLInputElement,
  CurrencyConverterProps
>(({ convertFrom, convertTo, size, id, ...rest }, ref) => {
  const handleWrapperClick = () => {
    const input = document.querySelector(`#${id}`) as HTMLInputElement;

    if (input) {
      input.focus();
    }
  };

  return (
    <div
      className={cx("CurrencyConverter", size || null)}
      onClick={handleWrapperClick}
    >
      <div className={"inputWrapper"}>
        <span className={"currency"}>{convertFrom.currency}</span>
        <input
          id={id}
          className={"input"}
          ref={ref}
          {...rest}
          type="number"
          step={"any"}
        />
      </div>
      <div className={"inputWrapper"}>
        <span className={"convertTo"}>$ {convertTo.value}</span>
        <span className={"icon"}>
          <ConvertIcon />
        </span>
      </div>
    </div>
  );
});
