import React, { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  CandlestickController,
  CandlestickElement,
  OhlcController,
  OhlcElement,
} from "chartjs-chart-financial";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
  TimeSeriesScale,
} from "chart.js";
import s from "./HomeDetails.module.scss";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  CandlestickController,
  CandlestickElement,
  TimeSeriesScale
);

const ChartFinancialDetails = ({ data }) => {
  const canvasFin = useRef(null);
  const [greenGradient, setGreenGradient] = useState("#43BACE");

  useEffect(() => {
    const gradient = canvasFin.current?.ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, "#71B54D");
    gradient.addColorStop(1, "#43BACE");
    setGreenGradient(gradient);
  }, []);

  const options = {
    responsive: true,
    aspectRatio: 2,
    scales: {
      y: {
        beginAtZero: false,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 8,
          },
          color: "#5E5E5E",
        },
      },
    },
    barThickness: 12,
  };

  const barData = {
    datasets: [
      {
        label: "candlestick",
        data,
        borderWidth: 0,
        borderRadius: 12,
        width: 12,
        color: {
          up: greenGradient,
          down: "#fe0000",
          unchanged: greenGradient,
        },
        borderColor: {
          up: greenGradient,
          down: "#fe0000",
          unchanged: greenGradient,
        },
      },
    ],
  };

  return (
    <div className={s.chartWrapper}>
      <Chart
        ref={canvasFin}
        type="candlestick"
        data={barData}
        options={options}
      />
    </div>
  );
};

export default ChartFinancialDetails;
