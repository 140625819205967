/* eslint-disable */
import { useState, useRef } from "react";
import { DotsButton } from "components/DotsButton/DotsButton";
import cx from "classnames";
import { ListModal } from "components/ListModal/ListModal";
import { ReactComponent as EyeIcon } from "./img/eye.svg";
import { ReactComponent as SendIcon } from "./img/send.svg";
import s from "./TransactionsList.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux";
import { showDrawer } from "../../redux/features/ui/uiSlice";

const typeClasses = {
  income: "s.income",
  outcome: "s.income",
};

const TransactionListItem = ({
  transaction,
  handleToggleSettings,
  settings,
  modalRef,
}: any) => {
  const transactionType = transaction.type === "income" ? "+" : "-";
  const active = transaction.id === settings.id;
  const transactionStyle = transaction.type === "income" ? s.income : s.outcome;
  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector((state) => state.wallet?.tokensInfo);

  const handleSend = (trans: any) => {
    const token = tokensInfo.filter((el: any) => el.name === trans?.network)[0];
    if (token) {
      const transaction = {
        decimals: token?.decimals,
        balance: token?.balance,
        usdPrice: token?.usdPrice,
        network: token?.name,
        to: trans?.to,
        from: trans?.from,
        derivationPath: token?.derivationPath,
      };
      dispatch(
        showDrawer(`transactions-send-tokens`, {
          transaction,
        })
      );
    }
  };

  const handleSeeDetails = (trans: any) => {
    dispatch(
      showDrawer("transactions-see-details", {
        trans,
      })
    );
  };

  return (
    <li
      key={transaction.id}
      className={cx(s.listItem, transactionStyle, active ? s.active : null)}
    >
      <ListModal showModal={active} modalRef={modalRef}>
        <div className={s.inner}>
          <div
            onClick={() => handleSeeDetails(transaction)}
            className={s.optionWrapper}
          >
            <div className={s.iconWrapper}>
              <EyeIcon />
            </div>
            <span className={s.option}>See details</span>
          </div>
          <div
            onClick={() => handleSend(transaction)}
            className={s.optionWrapper}
          >
            <div className={s.iconWrapper}>
              <SendIcon />
            </div>
            <span className={s.option}>Send tokens</span>
          </div>
        </div>
      </ListModal>

      <div className={s.header}>
        {/* <h2 className={s.title}>{transaction.name}</h2> */}
        <h2 className={s.title}>{transaction.network}</h2>
        <DotsButton
          active={active}
          onClick={() => handleToggleSettings(transaction.id)}
        />
      </div>
      <div className={s.body}>
        <div className={s.details}>
          <div className={s.value}>
            <span className={s.valueCurrency}>
              {transactionType} {transaction.amount}
            </span>
            <span className={s.valueFiat}>$ {transaction.usdPrice}</span>
          </div>
          <div className={s.type}>
            <div className={s.address}>
              <span>From:</span>
              {/* <span className={s.wallet}>{transaction.wallet}</span> */}
              <div className={s.wallet}>{transaction.from}</div>
            </div>
          </div>
        </div>
        <div className={s.dateWrapper}>
          <span className={s.time}>
            {transaction.date?.toLocaleTimeString()}
          </span>
          <span className={s.date}>
            {transaction.date?.toLocaleDateString()}
          </span>
        </div>
      </div>
    </li>
  );
};

export const TransactionsList = ({ transactionsData }: any) => {
  const modalRef = useRef(null);
  // const modalRef = useClickOutside(() => {
  //   setSettings(initial);
  // });

  const initial = {
    id: null,
  };
  const [settings, setSettings] = useState(initial);
  const handleToggleSettings = (id: any) => {
    if (settings.id === id) {
      setSettings({
        ...initial,
      });
    } else {
      setSettings({
        id,
      });
    }
  };
  return (
    <ol className={s.list}>
      {transactionsData.map((transaction: any) => (
        <TransactionListItem
          key={transaction.id}
          settings={settings}
          handleToggleSettings={handleToggleSettings}
          transaction={transaction}
          modalRef={modalRef}
        />
      ))}
    </ol>
  );
};
