import type {
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegisterReturn,
} from "react-hook-form";
import { useState } from "react";
import s from "./ThirdStep.module.scss";
import { FormHeader } from "./FormHeader";
import { FormInput, Checkbox, Button } from "..";
import { useAppSelector } from "../../redux";

interface ThirdStepProps {
  confirmPasswordRegister: UseFormRegisterReturn;
  passwordRegister: UseFormRegisterReturn;
  // checkbox01Register: UseFormRegisterReturn;
  checkbox02Register: UseFormRegisterReturn;
  checkbox03Register: UseFormRegisterReturn;
  errors: FieldErrorsImpl<DeepRequired<CreateFormData>>;
}

export const ThirdStep = ({
  confirmPasswordRegister,
  passwordRegister,
  // checkbox01Register,
  checkbox02Register,
  checkbox03Register,
  errors,
}: ThirdStepProps) => {
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [confirmPasswordInputType, setConfirmPasswordInputType] =
    useState("password");
  const createWalletInProgress = useAppSelector(
    (state) => state.auth?.createWalletInProgress
  );
  return (
    <>
      <FormHeader
        title="Create Pasword"
        desc="This password will unlock your EcoWay wallet only on this service."
      />
      <div className={s.formWrapper}>
        <div className={s.inputWrapper}>
          <FormInput
            placeholder=" "
            type={passwordInputType}
            name="password"
            label="New Password"
            desc="min. 8 characters"
            onChange={passwordRegister.onChange}
            onBlur={passwordRegister.onBlur}
            ref={passwordRegister.ref}
            errors={errors}
            variant="password"
            onClick={() =>
              setPasswordInputType((prevState) =>
                prevState === "password" ? "text" : "password"
              )
            }
          />
        </div>
        <div className={s.inputWrapper}>
          <FormInput
            variant="password"
            placeholder=" "
            type={confirmPasswordInputType}
            name="confirmPassword"
            label="Confirm New Password"
            onChange={confirmPasswordRegister.onChange}
            onBlur={confirmPasswordRegister.onBlur}
            ref={confirmPasswordRegister.ref}
            errors={errors}
            onClick={() =>
              setConfirmPasswordInputType((prevState) =>
                prevState === "password" ? "text" : "password"
              )
            }
          />
        </div>
        <div className={s.checkboxWrapper}>
          {/* <Checkbox
            onChange={checkbox01Register.onChange}
            ref={checkbox01Register.ref}
            onBlur={checkbox01Register.onBlur}
            name="checkbox01"
            errors={errors}
          >
            Remember Me
          </Checkbox> */}
          <Checkbox
            onChange={checkbox02Register.onChange}
            ref={checkbox02Register.ref}
            onBlur={checkbox02Register.onBlur}
            name="checkbox02"
            errors={errors}
          >
            I have read and agree for a Term of Use
          </Checkbox>
          <Checkbox
            onChange={checkbox03Register.onChange}
            ref={checkbox03Register.ref}
            onBlur={checkbox03Register.onBlur}
            name="checkbox03"
            errors={errors}
          >
            I understand that EcoWay cannot recover this password for me.
          </Checkbox>
        </div>
        <div className={s.ctaWrapper}>
          <Button
            size="big"
            variant="primary"
            type="submit"
            isLoading={createWalletInProgress}
          >
            Create Password
          </Button>
        </div>
      </div>
    </>
  );
};
