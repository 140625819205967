/* eslint-disable */
import s from "./HomeDetailsTile.module.scss";
import chia from "./img/chia.svg";

export const HomeDetailsTile = ({ coin }: any) => {
  // const isProfit = (buyPrice: number, price: number) => buyPrice > price;

  return (
    <div className={s.tile}>
      <div className={s.left}>
        <h3 className={s.coinName}>{coin.name} </h3>
        <div className={s.coinValue}>
          <span className={s.value}>{Number(coin.balance).toFixed(2)}</span>
          <span className={s.coinPrice}>
            $ {Number(Number(coin.balance) * Number(coin.usdPrice)).toFixed(2)}
          </span>
        </div>
      </div>

      <div className={s.right}>
        <div className={s.logo}>
          {coin.logoSrc ? <img src={chia} alt={coin.name} /> : null}
        </div>
      </div>
    </div>
  );
};
