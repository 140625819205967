const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string
) => {
  if (typeof variable !== "string") {
    throw Error(
      `You need to include all necessary variables inside .env file in the root of your project to run application. First missing variable: ${expectedVariableName}.`
    );
  }
  return variable;
};

export const config = {
  API_URL: envVariableValidator(process.env.REACT_APP_API_URL, "API_URL"),
  STAKING_APP_API_URL: envVariableValidator(
    process.env.REACT_APP_STAKING_APP_API_URL,
    "STAKING_API_URL"
  ),
  NODE_ENVIRONMENT: envVariableValidator(
    process.env.REACT_APP_NODE_ENV,
    "NODE_ENV"
  ),
  DB_STORE_TRANSACTION: envVariableValidator(
    process.env.REACT_APP_DB_STORE_TRANSACTION,
    "DB_STORE_TRANSACTION"
  ),
  DB_STORE_ADDRESS_BOOK: envVariableValidator(
    process.env.REACT_APP_DB_STORE_ADDRESS_BOOK,
    "DB_STORE_ADDRESS_BOOK"
  ),
  STABLE_NORMAL_FEE_PRICE: envVariableValidator(
    process.env.REACT_APP_STABLE_NORMAL_FEE_PRICE,
    "STABLE_NORMAL_FEE_PRICE"
  ),
  STABLE_FAST_FEE_PRICE: envVariableValidator(
    process.env.REACT_APP_STABLE_FAST_FEE_PRICE,
    "STABLE_FAST_FEE_PRICE"
  ),
};
