/* eslint-disable */
export const sortUserAlphabetically = (users: any) => {
  const arrayToSort = [...users];
  return arrayToSort.sort((a: any, b: any) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1;
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1;
    }
    return 0;
  });
};
