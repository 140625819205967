import s from "components/CreateForm/FormHeader.module.scss";

interface FormHeaderProps {
  title: string;
  desc: string;
}

export const FormHeader = ({ title, desc }: FormHeaderProps) => (
  <div className={s.stepWrapper}>
    <div className={s.sectionContent}>
      <h2 className={s.sectionTitle}>{title}</h2>
      <p className={s.sectionDesc}>{desc}</p>
    </div>
  </div>
);
