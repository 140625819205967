/* eslint-disable */
import { useState } from "react";
import { BackButton } from "components/BackButton/BackButton";
import cx from "classnames";
import { filterByName } from "helpers/filterByName";
import { sortUserAlphabetically } from "helpers/sortUserAlphabetically";
import s from "./HomeAddAssets.module.scss";
import { HomeTile } from "./HomeTile";
import { ReactComponent as SearchIcon } from "./img/search.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Helmet } from "react-helmet-async";

export const HomeAddAssets = () => {
  const [showInput, setShowInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleToggleShowInput = () => {
    setShowInput((prevState) => !prevState);
  };

  const { tokensInfo } = useAppSelector((state: RootState) => state.wallet);

  const dispatch = useAppDispatch();

  const filterArray = filterByName(tokensInfo, searchValue);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Home - Add assets</title>
      </Helmet>
      <section className={s.home}>
        <header className={s.header}>
          <BackButton />
          <div className={cx(s.searchBar, showInput ? s.active : null)}>
            {showInput && (
              <div className={s.inputWrapper}>
                <input
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                  className={s.input}
                  type="text"
                />
              </div>
            )}
            <button onClick={handleToggleShowInput} className={s.searchBtn}>
              <div className={s.searchIcon}>
                <SearchIcon />
              </div>
            </button>
          </div>
        </header>
        <div className={s.coinWrapper}>
          {tokensInfo &&
            sortUserAlphabetically(tokensInfo).map((coin: any) =>
              coin?.name !== "chia" ? (
                <HomeTile key={coin.id} coin={coin} />
              ) : null
            )}
        </div>
      </section>
    </>
  );
};
