import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState } from "react";
import { QrReader } from "react-qr-reader";
import { hideDrawer } from "redux/features/ui/uiSlice";
import { Button } from "../../Button/Button";
import { config } from "../../../config";
import {
  useAppDispatch,
  useAppSelector,
  useDatabaseContext,
} from "../../../redux";
import { ReactComponent as ScanIcon } from "../../../assets/icons/scan.svg";
import { FormInput } from "../../FormInput/FormInput";
import drawerStyles from "../Drawer.module.scss";
import s from "./AddAddressDrawer.module.scss";

type AddAddressFormDataType = {
  name: string;
  address: string;
};

export const AddAddressDrawer = () => {
  const dispatch = useAppDispatch();

  const { handleSubmit, register, reset, setValue } =
    useForm<AddAddressFormDataType>();
  const { db, databaseIsReady } = useDatabaseContext();
  const drawer = useAppSelector((state) => state?.ui?.drawer);

  const onHandleAddAddressBookRecord = async (data: AddAddressFormDataType) => {
    try {
      const { name, address } = data;
      const obj = {
        name,
        address,
      };
      if (databaseIsReady && db)
        db.createObject(config.DB_STORE_ADDRESS_BOOK, obj);
      dispatch(hideDrawer());
      reset();
      drawer.data.onSuccess();
    } catch (error) {
      toast.error(
        "Failed to add a record to the address book, try again later."
      );
    }
  };

  const [isQrScannerVisible, setIsQrScannerVisible] = useState(false);

  const toggleQrScanner = () => {
    setIsQrScannerVisible((prev) => !prev);
  };

  return (
    <form
      onSubmit={handleSubmit(onHandleAddAddressBookRecord)}
      className={drawerStyles.wrapper}
    >
      <h3 className={drawerStyles.wrapper__title}>Add address</h3>
      <p className={drawerStyles.wrapper__description}>
        Enter the address and name to add a new record to the address book
      </p>
      <div style={{ paddingTop: "10px" }}>
        <FormInput
          variant="text"
          placeholder="Type name"
          label="Name"
          {...register("name", {
            required: true,
          })}
        />
      </div>
      <div style={{ paddingTop: "10px" }} className={s.scan}>
        <FormInput
          variant="text"
          placeholder="Type or Scan Address"
          label="Address"
          {...register("address", {
            required: true,
          })}
        />
        <span className={s.scan__icon}>
          <ScanIcon
            style={{ cursor: "pointer" }}
            onClick={() => toggleQrScanner()}
          />
        </span>
      </div>
      {isQrScannerVisible ? (
        <QrReader
          videoContainerStyle={{ paddingTop: "50%" }}
          containerStyle={{ marginTop: "24px" }}
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (result) {
              setValue("address", result?.getText());
              setIsQrScannerVisible(false);
            }
            if (error?.message === "Permission denied") {
              setIsQrScannerVisible(false);
            }
          }}
        />
      ) : null}
      <div className={drawerStyles.wrapper__buttons}>
        <Button
          onClick={() => dispatch(hideDrawer())}
          type="button"
          variant="secondary"
          size="small"
        >
          Cancel
        </Button>
        <Button type="submit" variant="primary" size="small">
          Add
        </Button>
      </div>
    </form>
  );
};
