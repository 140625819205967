/* eslint-disable */
import React from "react";
import cx from "classnames";
import { ChangeHandler, FieldErrorsImpl } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import s from "./Checkbox.module.scss";

type CheckboxProps = {
  name: string;
  children: React.ReactNode;
  checked?: boolean;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  errors?: any;
  color?: "dark";
  value?: string | number | readonly string[] | undefined;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { name, color, children, onChange, checked, errors, ...checkBoxProps },
    ref
  ) => (
    <div className={cx(s.checkbox, color ? s[`${color}`] : null)}>
      <label className={s.label} htmlFor={name}>
        <input
          ref={ref}
          id={name}
          name={name}
          type="checkbox"
          onChange={onChange}
          {...checkBoxProps}
        />
        <span
          className={cx(s.check, checked ? s.checkboxActive : null)}
          aria-hidden="true"
        />
        {children}
      </label>
      {errors && errors[`${name}`] ? (
        <span className={s.error}>{errors[`${name}`].message}</span>
      ) : null}
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <span className={s.error}>{message}</span>
          ))
        }
      />
    </div>
  )
);
