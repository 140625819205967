import "./Button.scss";
import cx from "classnames";
import { Loader } from "../Loader/Loader";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  size: "small" | "big";
  variant: "primary" | "secondary";
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  fullWidth?: boolean;
};

export const Button = ({
  children,
  onClick,
  size,
  variant,
  disabled,
  type = "submit",
  isLoading,
  className,
  fullWidth,
}: ButtonProps) => (
  <button
    disabled={(disabled && disabled) || (isLoading && isLoading)}
    type={type}
    className={cx(
      "Button",
      size,
      variant,
      className,
      fullWidth ? "fullWidth" : undefined
    )}
    onClick={onClick}
  >
    {isLoading ? (
      <div style={{ width: "60px", height: "60px" }}>
        <Loader />
      </div>
    ) : (
      children
    )}
  </button>
);
