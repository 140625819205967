import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import s from "./Tile.module.scss";

export const Tile = (props: TokensInfo) => {
  const { name, balance, usdValue, logoSrc, percentage, decimals } = props;

  return (
    <li className={s.tile}>
      <div className={`${s.left} ${s.forksLeft}`}>
        <h3 className={s.name}>{name}</h3>
        <p className={s.value}>{ethers.utils.formatUnits(balance, decimals)}</p>
        <span className={s.currency}>
          $ {new BigNumber(usdValue).toFixed(2)}
        </span>
      </div>
      <div className={s.right}>
        <div className={s.data}>
          <div className={s.logo}>
            {logoSrc ? <img src={logoSrc} alt={name} width={59} /> : null}
          </div>
          <div className={s.percentages}>{`(${percentage}%)`}</div>
        </div>
      </div>
    </li>
  );
};
