import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux";
import { Tile, RoundedChart, Loader } from "../../components";
import chartImage from "../../assets/images/chart.png";
import s from "./Portfolio.module.scss";
import { getTokensInfo } from "../../redux/features/wallet/walletSlice";
import { PATHS } from "../../constants";

// import {ForMockData, newMockData, mockDataForTestRoundBar} from "./mockData";

interface CoinDataForRoundedChart {
  value: number;
  color: string;
  percentage: string;
  name: string;
  id: number;
}

export const Portfolio = () => {
  const dispatch = useAppDispatch();
  const { hashes } = useAppSelector((state: RootState) => state.auth);
  const isLoading = useAppSelector((state) => state.wallet.isLoading);
  const tokensInfo = useAppSelector((state) => state.wallet?.tokensInfo);
  const [roundedChartData, setRoundedChartData] = useState<
    CoinDataForRoundedChart[]
  >([]);
  const [roundedChartLabels, setRoundedChartLabels] = useState<string[]>([]);

  const roundedChartColors = ["#00A3BE", "#017083", "#71B54D", "#202427"];

  const generateRoundBar = (data: TokensInfo[], colors: string[]) => {
    const dataArray = [];
    const labelsArray = [];
    let othersValue = 0;

    const sortedArray = [...data].sort((obj1, obj2) =>
      obj1.balance > obj2.balance ? -1 : 1
    );

    sortedArray.forEach(({ balance, currency, decimals }, index) => {
      if (index < 3) {
        const dataObj = {
          decimals,
          value: balance,
          color: colors[index],
          name: currency,
          id: index,
        };
        dataArray.push(dataObj);
        labelsArray.push(currency);
      } else {
        othersValue += Number(balance);
      }
    });

    if (othersValue > 0) {
      const dataObj = {
        value: Number(othersValue.toFixed(2)),
        color: colors[3],
        name: "others",
        id: 4,
      };
      labelsArray.push("others");
      dataArray.push(dataObj);
    }

    const valuesArray = dataArray.map((el) => Number(el.value));
    const sum = valuesArray.reduce((acc, curr) => acc + curr, 0);

    const dataArrayWithPercentage = dataArray
      .map((obj) => {
        const objWithPercentage = {
          ...obj,
          percentage: ((obj.value / sum) * 100).toFixed(0),
        };
        return objWithPercentage;
      })
      .filter((dataItem) => Number(dataItem.value) > 0);

    setRoundedChartData(dataArrayWithPercentage);
    setRoundedChartLabels(labelsArray);
  };

  useEffect(() => {
    dispatch(getTokensInfo(hashes));
  }, [hashes]);

  useEffect(() => {
    if (tokensInfo) {
      generateRoundBar(tokensInfo, roundedChartColors);
    }
  }, [tokensInfo]);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Portfolio</title>
      </Helmet>
      <section className={s.portfolio}>
        {isLoading ? (
          <div style={{ position: "absolute", top: "auto", left: "auto" }}>
            <Loader />
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!tokensInfo || tokensInfo.length === 0 ? (
              <div className={s.noTokensInfo}>
                <img
                  src={chartImage}
                  className={s.noTokensInfo__image}
                  alt=""
                />
                <p>
                  Currently you do not have any assets. We will update your
                  portfolio after first farming period.
                </p>
                <Link to={PATHS.HOME} className={s.noTokensInfo__link}>
                  Back to home
                </Link>
              </div>
            ) : (
              <>
                <div className={s.total}>
                  <div className={s.chartWrapper}>
                    <RoundedChart
                      coins={roundedChartData}
                      labels={roundedChartLabels}
                    />
                  </div>
                  <div className={s.totalFooter}>
                    <ul className={s.stats}>
                      {roundedChartData.map(
                        ({
                          id,
                          name,
                          percentage,
                          color,
                          value,
                        }: CoinDataForRoundedChart) =>
                          value > 0 ? (
                            <li key={`coin-${id}`} className={s.statsItem}>
                              <span
                                style={{ background: color }}
                                className={s.dotColor}
                              />
                              <span className={s.name}>{name}</span>
                              <span className={s.value}>({percentage}%)</span>
                            </li>
                          ) : null
                      )}
                    </ul>
                  </div>
                </div>
                <div className={s.listWrapper}>
                  {tokensInfo &&
                    tokensInfo.map((item: TokensInfo) => (
                      <Tile key={item.id} {...item} />
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};
