import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "../index";
import { DatabaseManager, DatabaseManagerEventName } from "../../helpers";
import { config } from "../../config";

interface IDatabase {
  db: DatabaseManager | undefined;
  databaseIsReady: boolean;
}

const DatabaseContextProvider = createContext<IDatabase | undefined>(undefined);

export const useDatabaseContext = () => {
  // get the context
  const context = useContext(DatabaseContextProvider);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("DatabaseContextProvider was used outside of its Provider");
  }

  return context;
};

export const DatabaseManagerContext: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isAuth = useAppSelector((state) => state.auth?.isAuth);
  const walletAddress = useAppSelector((state) => state.auth?.walletAddress);
  const [databaseIsReady, setDatabaseIsReady] = useState(false);
  const [db, setDb] = useState<DatabaseManager | undefined>(undefined);
  useEffect(() => {
    if (isAuth) {
      setDb(
        new DatabaseManager(walletAddress, [
          config.DB_STORE_TRANSACTION,
          config.DB_STORE_ADDRESS_BOOK,
        ])
      );

      window.addEventListener(DatabaseManagerEventName, () => {
        setDatabaseIsReady(true);
      });
    }

    return () => {
      if (db) {
        db.closeDatabase();
        setDatabaseIsReady(false);
      }
    };
  }, [isAuth]);
  const contextValue = useMemo(
    () => ({ db, databaseIsReady }),
    [isAuth, databaseIsReady, db]
  );
  return (
    <DatabaseContextProvider.Provider value={contextValue}>
      {children}
    </DatabaseContextProvider.Provider>
  );
};
