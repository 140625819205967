/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import { HomeDetailsTile } from "./HomeDetailsTile";
import s from "./HomeDetails.module.scss";
import { Chart } from "react-chartjs-2";
import { FormInput } from "components/FormInput/FormInput";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
  TimeSeriesScale,
} from "chart.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  TimeSeriesScale,
  PointElement,
  LineElement,
  LineController,
  BarController
);

const ChartDetails = ({ data }) => {
  const canvas = useRef(null);
  const weeklyLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const fourHourLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [greenGradient, setGreenGradient] = useState("#43BACE");

  useEffect(() => {
    const gradient = canvas.current?.ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, "#71B54D");
    gradient.addColorStop(1, "#43BACE");
    setGreenGradient(gradient);
  }, []);

  const options = {
    responsive: true,
    aspectRatio: 2,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 8,
          },
          color: "#5E5E5E",
        },
      },
    },
    barThickness: 12,
  };

  const farmedData = {
    labels: weeklyLabels,
    datasets: [
      {
        label: "$",
        data: data,
        backgroundColor: [greenGradient],
        borderWidth: 0,
        borderRadius: 12,
      },
    ],
  };

  return (
    <div className={s.chartWrapper}>
      <Chart ref={canvas} type="bar" data={farmedData} options={options} />
    </div>
  );
};

export default ChartDetails;
