/* eslint-disable */
import React from "react";
import "./Settings.scss";
import { ReactComponent as LogoutIcon } from "./img/logout.svg";
import { ReactComponent as Tick } from "./img/tick.svg";
import { ReactComponent as Email } from "./img/email.svg";
import { ReactComponent as Telegram } from "./img/telegram.svg";
import { useAppDispatch, useAppSelector } from "../../redux";
import { setTheme } from "../../redux/features/ui/uiSlice";
import { handleLogout } from "../../redux/features/auth/authSlice";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "components/Button/Button";

export const Settings = () => {
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector((state) => state?.ui?.theme);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Settings</title>
      </Helmet>
      <div className="settings">
        <h2 className="title">Light/Dark Mode</h2>
        <div className="settings-box">
          <div
            className={
              currentTheme === "light"
                ? "settings-item active"
                : "settings-item"
            }
            onClick={() => dispatch(setTheme("light"))}
          >
            Always light <Tick />
          </div>
          <div
            className={
              currentTheme === "dark" ? "settings-item active" : "settings-item"
            }
            onClick={() => dispatch(setTheme("dark"))}
          >
            Always dark <Tick />
          </div>
        </div>
        <h2 className="title">Address book</h2>
        <div className="settings-box">
          <Link
            className="settings-item"
            style={{ textDecoration: "none" }}
            to="/address-book"
          >
            Manage Address Book
          </Link>
        </div>
        <h2 className="title">Contact us</h2>
        <div className="settings-box">
          <a
            className="settings-item link-item"
            href="mailto: contact@ecoway.org"
          >
            <Email />
            contact@ecoway.org
          </a>
          <a
            className="settings-item link-item"
            href="https://t.me/ecoway_org"
            target="_blank"
            rel="noreferrer"
          >
            <Telegram />
            EcoWay
          </a>
        </div>
        <Button
          className="logout"
          size="big"
          variant="primary"
          onClick={() => dispatch(handleLogout())}
        >
          <LogoutIcon /> Log out
        </Button>
      </div>
    </>
  );
};
