/* eslint-disable */
// import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import type { RootState, TypedDispatch } from "./store/store";
import { useState, useEffect, useRef } from "react";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = () => useDispatch<TypedDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useOnScreen(options: IntersectionObserverInit | undefined) {
  const screen = getWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const [visable, setVisable] = useState(false);
  useEffect(() => {
    const refVar = ref.current;
    if (typeof window !== "undefined") {
      const observer = new IntersectionObserver(([entry]) => {
        setVisable(entry.isIntersecting);
      }, options);

      if (refVar) {
        observer.observe(refVar);
      }

      return () => {
        if (refVar) {
          observer.unobserve(refVar);
        }
      };
    }
  }, [ref, options]);

  return [ref, visable] as const;
}

export function useClickOutside(handler: () => void) {
  const domNode = useRef<any>(null);

  useEffect(() => {
    const onClickHandler = (event: any) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", onClickHandler);
    return () => {
      document.removeEventListener("mousedown", onClickHandler);
    };
  });

  return domNode;
}

export function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current?.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },

    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
