/* eslint-disable */
import { Button } from "components/Button/Button";
import { useEffect, useMemo, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm, Controller } from "react-hook-form";
import { Checkbox } from "components/Checkbox/Checkbox";
import { FormInput } from "components/FormInput/FormInput";
import { CurrencyConverter } from "components/CurrencyConverter/CurrencyConverter";
import { UnderlineButton } from "../../components/UnderlineButton/UnderlineButton";
import "./Send.scss";
import { ReactComponent as ScanIcon } from "./img/scan.svg";
import { SendAddressBook } from "./SendAddressBook";
import {
  useAppDispatch,
  useAppSelector,
  useDatabaseContext,
} from "../../redux";
import { showDrawer } from "../../redux/features/ui/uiSlice";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { config } from "../../config";
import { Helmet } from "react-helmet-async";
import { getTokensInfo } from "../../redux/features/wallet/walletSlice";
import { QrReader } from "react-qr-reader";
import { BaseCheckbox } from "../../components";
import { useNavigate } from "react-router-dom";

export type UserDataType = {
  name: string;
  id: string;
};

type TokensSelectType = {
  name: string;
  value: string;
};

type FormData = {
  addressTo: string;
  token: any;
  value: number | string;
  checkboxFee: string;
};

export const Send = () => {
  const { db, databaseIsReady } = useDatabaseContext();
  const { hashes } = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.auth?.walletAddress);
  const tokensInfo = useAppSelector((state) => state.wallet?.tokensInfo);
  const [userData, setUserData] = useState<UserDataType[]>([]);
  const walletAddresses = useAppSelector(
    (state) => state.auth?.walletAddresses
  );
  const [addressBookData, setAddressBookData] = useState<
    databaseAddressBookType[] | []
  >([]);
  const [recentData, setRecentData] = useState([]);
  const [showAddressBook, setShowAddressBook] = useState(false);
  const [showRecentList, setShowRecentList] = useState(false);
  const [recentListData, setRecentListData] = useState<
    databaseAddressBookType[]
  >([]);
  const [advance, setAdvance] = useState(false);
  const [step, setStep] = useState(0);
  const renderRecentList = addressBookData?.length > 0 && showAddressBook;
  const [tokensSelectOptions, setTokensSelectOptions] = useState<
    TokensSelectType[]
  >([]);
  const [balances, setBalances] = useState(0);
  const [currentTo, setCurrentTo] = useState("0");
  const [token, setToken] = useState<any>(null);
  const [currentGasTo, setCurrentGasTo] = useState("0");
  const stableFastFeePrice = String(config.STABLE_FAST_FEE_PRICE);
  const stableNormalFeePrice = String(config.STABLE_NORMAL_FEE_PRICE);
  const [fastFeePrice, setFastFeePrice] = useState("0");
  const [normalFeePrice, setNormalFeePrice] = useState("0");
  const [summaryFee, setSummaryFee] = useState("0");
  const [isQrScannerVisible, setIsQrScannerVisible] = useState(false);
  const [qrScanData, setQrScanData] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<FormData>({
    reValidateMode: "onChange",
  });

  const navigate = useNavigate();

  const getAddressBookItems = async () => {
    if (databaseIsReady) {
      await db?.getAllObjects(config.DB_STORE_ADDRESS_BOOK, setAddressBookData);
    }
  };

  const getAllWalletAddress = () => {
    if (databaseIsReady) {
      db?.getAllObjects(config.DB_STORE_TRANSACTION, (_array: any[]) => {
        const _newArray: databaseAddressBookType[] = [];
        const _reverseArray: any[] = _array.reverse();
        for (const [index, trans] of _reverseArray.entries()) {
          if (index === 5) break;
          _newArray.push({
            id: trans?.id,
            address: trans?.to,
            name: String(trans?.network),
          });
        }

        setRecentListData(_newArray);
      });
    }
  };

  const handleSetRecipientAddress = (address: string) => {
    setValue("addressTo", address);
    setShowAddressBook(false);
  };

  const onHandleRecentList = (address: string) => {
    setValue("addressTo", address);
    setShowRecentList(false);
  };

  const toggleQrScanner = () => {
    setIsQrScannerVisible(!isQrScannerVisible);
    setShowRecentList(false);
    setShowAddressBook(false);
  };

  const currentCheckboxFee = watch("checkboxFee");

  useEffect(() => {
    const value = new BigNumber(getValues("checkboxFee"));
    const usdPriceBig = new BigNumber(token?.usdPrice);
    setSummaryFee(value.multipliedBy(usdPriceBig).toFixed(2));
  }, [currentCheckboxFee]);

  useEffect(() => {
    getAllWalletAddress();
  }, [showRecentList]);

  useEffect(() => {
    const selectOption = getValues("token");
    if (selectOption) {
      setToken(tokensInfo.find((item: any) => item?.id == selectOption));
    } else if (tokensInfo) {
      setToken(tokensSelectOptions[0]);
      setValue("token", 0);
    }
  }, [watch("token")]);

  useEffect(() => {
    getAddressBookItems();
  }, [showAddressBook]);

  useEffect(() => {
    const newArray = tokensInfo
      .filter((item: any) => item?.balance > 0)
      .map((el: any) => ({ ...el, value: el?.id }));
    setTokensSelectOptions(newArray);
  }, []);

  const onHandleSubmit = (data: FormData) => {
    setStep(1);
  };

  useEffect(() => {
    if (token) {
      const usdPriceBig = new BigNumber(token?.usdPrice || 0);
      setFastFeePrice(
        new BigNumber(
          ethers.utils.formatUnits(stableFastFeePrice, token?.decimals)
        )
          .multipliedBy(usdPriceBig)
          .toFixed(2)
      );
      setNormalFeePrice(
        new BigNumber(
          ethers.utils.formatUnits(stableNormalFeePrice, token?.decimals)
        )
          .multipliedBy(usdPriceBig)
          .toFixed(2)
      );
    }
  }, [token]);

  useEffect(() => {
    const currentValue = getValues("value");
    const currentGas = getValues("checkboxFee");
    if (token && currentValue > 0) {
      const usdPriceBig = new BigNumber(token?.usdPrice || 0);
      const valueBig = new BigNumber(currentValue);
      const sum = valueBig.multipliedBy(usdPriceBig).toFixed(2);

      setCurrentTo(sum);
    } else {
      setCurrentTo("0");
    }

    if (token && Number(currentGas) > 0) {
      const usdPriceBig = new BigNumber(token?.usdPrice || 0);
      const valueBig = new BigNumber(currentGas);
      const sum = valueBig.multipliedBy(usdPriceBig).toFixed(2);
      setCurrentGasTo(sum);
    } else {
      setCurrentGasTo("0");
    }
  }, [watch("value"), token, currentCheckboxFee]);

  useEffect(() => {
    dispatch(getTokensInfo(hashes));
  }, []);

  useEffect(() => {
    if (qrScanData) {
      setValue("addressTo", qrScanData);
    } else {
      setValue("addressTo", "");
    }
  }, [qrScanData]);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Send</title>
      </Helmet>
      <div className="Send">
        {step === 0 && (
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="frame">
              <h2 className="frame-title">Recipient</h2>
              <div className="address-scan">
                <input
                  placeholder="Type or Scan address"
                  className="input"
                  type="text"
                  {...register("addressTo", {
                    required: "Field is required",
                    pattern: {
                      value: /[a-z0-9]{62}/,
                      message: "Bad wallet address",
                    },
                  })}
                />
                <span className="icon">
                  <ScanIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleQrScanner()}
                  />
                </span>
              </div>
              <ErrorMessage errors={errors} name="addressTo" />
              <div className="footer">
                <UnderlineButton
                  onClick={() => {
                    setShowRecentList((prevState) => !prevState);
                    setShowAddressBook(false);
                    setIsQrScannerVisible(false);
                  }}
                  icon
                >
                  Recent List
                </UnderlineButton>
                <UnderlineButton
                  onClick={() => {
                    setShowAddressBook((prevState) => !prevState);
                    setShowRecentList(false);
                    setIsQrScannerVisible(false);
                  }}
                  icon
                >
                  Use My Adress Book
                </UnderlineButton>
              </div>
            </div>
            {showRecentList && recentListData ? (
              <SendAddressBook
                setRecipient={onHandleRecentList}
                userData={recentListData}
              />
            ) : null}
            {showAddressBook && addressBookData ? (
              <SendAddressBook
                setRecipient={handleSetRecipientAddress}
                userData={addressBookData}
              />
            ) : null}
            {isQrScannerVisible ? (
              <QrReader
                videoContainerStyle={{ paddingTop: "50%" }}
                containerStyle={{ marginBottom: "25px" }}
                constraints={{ facingMode: "environment" }}
                onResult={(result, error) => {
                  if (!!result) {
                    setQrScanData(result?.getText());
                    setIsQrScannerVisible(false);
                  }
                  if (error?.message === "Permission denied") {
                    setIsQrScannerVisible(false);
                  }
                }}
              />
            ) : null}
            <div className="frame">
              <h2 className="frame-title">Asset</h2>

              <div className="amount-input">
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormInput
                      label="Choose Token"
                      variant="select"
                      type="select"
                      placeholder=""
                      value={value ? value : ""}
                      onChange={(e) => onChange(e)}
                      options={tokensSelectOptions}
                    />
                  )}
                  name="token"
                  // rules={{ required: "Field is required" }}
                />
              </div>
              <ErrorMessage errors={errors} name="token" />
            </div>
            <div className="frame">
              <h2 className="frame-title">Amount</h2>
              <div className="currency-converter">
                <CurrencyConverter
                  id="transfer-amount"
                  size="big"
                  convertFrom={{
                    value: getValues("value"),
                    currency:
                      token !== null || undefined ? token?.currency : "XCH",
                  }}
                  convertTo={{
                    value: token ? currentTo : 0,
                    currency: "$",
                  }}
                  {...register("value", {
                    required: "Field is required",
                    validate: {
                      isLessThan: (value) =>
                        Number(value) <=
                          (token
                            ? ethers.utils.formatUnits(
                                token?.balance,
                                token?.decimals
                              )
                            : 0) || "Not enough funds",
                      notEnoughFunds: (value) =>
                        Number(value) + Number(getValues("checkboxFee")) <=
                          (token
                            ? ethers.utils.formatUnits(
                                token?.balance,
                                token?.decimals
                              )
                            : 0) || "Not enough funds",
                      moreThan0: (value) =>
                        value > 0 || "The amount must be greater than 0",
                    },
                  })}
                />
                <ErrorMessage errors={errors} name="value" />
                <div className="link-wrapper">
                  <UnderlineButton
                    onClick={() =>
                      setValue(
                        "value",
                        token
                          ? ethers.utils.formatUnits(
                              token?.balance,
                              token?.decimals
                            )
                          : 0
                      )
                    }
                  >
                    Use Max Amount
                  </UnderlineButton>
                </div>
              </div>

              <h3 className="options-title">Gas Fee</h3>

              {advance ? (
                <div className="options-inner flex">
                  <CurrencyConverter
                    id="fee-value"
                    size="big"
                    convertFrom={{
                      value: getValues("checkboxFee"),
                      currency: token ? token?.currency : "XCH",
                    }}
                    convertTo={{
                      value: token ? currentGasTo : 0,
                      currency: "$",
                    }}
                    {...register("checkboxFee", {
                      required: "Field is required",
                    })}
                  />
                  <ErrorMessage errors={errors} name="checkboxFee" />
                </div>
              ) : (
                <div className="options-inner gas-options">
                  <div className="checkbox">
                    <div>
                      <Controller
                        name="checkboxFee"
                        control={control}
                        render={({ field }) => (
                          <BaseCheckbox
                            id="fast-fee"
                            label="Fast"
                            {...field}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setValue(
                                  "checkboxFee",
                                  token
                                    ? ethers.utils.formatUnits(
                                        stableFastFeePrice,
                                        token?.decimals
                                      )
                                    : "0"
                                );
                              }
                            }}
                            checked={
                              (token
                                ? ethers.utils.formatUnits(
                                    stableFastFeePrice,
                                    token?.decimals
                                  )
                                : "0") === currentCheckboxFee
                            }
                          />
                        )}
                      />
                    </div>

                    <div className="value">
                      <span className="desc-top">
                        {token
                          ? ethers.utils.formatUnits(
                              stableFastFeePrice,
                              token?.decimals
                            )
                          : 0}{" "}
                        {token?.currency}
                      </span>
                      <span className="desc-bottom">${fastFeePrice}</span>
                    </div>
                  </div>
                  <ErrorMessage errors={errors} name="checkboxFee" />
                  <div className="checkbox">
                    <div>
                      <Controller
                        name="checkboxFee"
                        control={control}
                        render={({ field }) => (
                          <BaseCheckbox
                            id="normal-fee"
                            label="Normal"
                            {...field}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setValue(
                                  "checkboxFee",
                                  token
                                    ? ethers.utils.formatUnits(
                                        stableNormalFeePrice,
                                        token?.decimals
                                      )
                                    : "0"
                                );
                              }
                            }}
                            checked={
                              (token
                                ? ethers.utils.formatUnits(
                                    stableNormalFeePrice,
                                    token?.decimals
                                  )
                                : "0") === currentCheckboxFee
                            }
                          />
                        )}
                      />
                    </div>

                    <div className="value">
                      <span className="desc-top">
                        {token
                          ? ethers.utils.formatUnits(
                              stableNormalFeePrice,
                              token?.decimals
                            )
                          : 0}{" "}
                        {token?.currency}
                      </span>
                      <span className="desc-bottom">${normalFeePrice}</span>
                    </div>
                  </div>
                  <ErrorMessage errors={errors} name="checkboxFee" />
                </div>
              )}

              <div className="link-wrapper">
                <UnderlineButton
                  onClick={() => setAdvance((prevState) => !prevState)}
                >
                  See Advanced Options
                </UnderlineButton>
              </div>
            </div>
            <div className="buttons">
              <Button
                size="small"
                variant="secondary"
                type="button"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                size="small"
                variant="primary"
                type="submit"
                // onClick={() => setStep(1)}
              >
                Next
              </Button>
            </div>
          </form>
        )}
        {step === 1 && (
          <div className="step-1">
            <h2 className="title">Ready to send?</h2>
            <div className="frame">
              <div className="frame-inner">
                <div className="left">
                  <span className="big-text">Amount</span>
                </div>
                <div className="right col">
                  <span className="big-text">
                    {" "}
                    {getValues("value")} {token?.currency}
                  </span>
                  <span className="small-text">$ {currentTo}</span>
                </div>
              </div>
            </div>
            <div className="frame">
              <div className="frame-inner">
                <div className="left">
                  <span className="big-text">Gas Fee</span>
                </div>
                <div className="right col">
                  <span className="big-text">
                    {" "}
                    {getValues("checkboxFee")} {token?.currency}
                  </span>
                  <span className="small-text">$ {summaryFee}</span>
                </div>
              </div>
            </div>
            <div className="frame">
              <div className="frame-inner frame-margin">
                <div className="left">
                  <span className="small-text">Status</span>
                </div>
                <div className="right col">
                  <span className="small-text">Before purchase</span>
                </div>
              </div>
              <div className="displaySpaceBetween">
                <span className="small-text" style={{ marginRight: "12px" }}>
                  From
                </span>

                <div className="displayText">
                  <span className="small-text">
                    {
                      walletAddresses.find(
                        (walletAddress: WalletAddress) =>
                          walletAddress.name === token?.name
                      ).address
                    }
                  </span>
                </div>
              </div>
              <div className="displaySpaceBetween">
                <span className="small-text" style={{ marginRight: "12px" }}>
                  To
                </span>

                <div className="displayText">
                  <span className="small-text">{getValues("addressTo")}</span>
                </div>
              </div>
              <div className="frame-inner">
                <div className="left">
                  <span className="small-text">Date</span>
                </div>
                <div className="right col">
                  <span className="small-text">
                    <strong>{new Date().toISOString().split("T")[0]}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="frame frame-total">
              <div className="frame-inner">
                <div className="left">
                  <span className="total-text">Total Amount</span>
                </div>
                <div className="right col">
                  <span className="big-text">
                    {" "}
                    {new BigNumber(getValues("value"))
                      .plus(new BigNumber(getValues("checkboxFee")))
                      .toString()}{" "}
                    {token?.currency}
                  </span>
                  <span className="small-text">
                    $ {BigNumber(currentTo).plus(summaryFee).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            <div className="buttons">
              <Button
                size="small"
                variant="secondary"
                type="button"
                onClick={() => setStep(0)}
              >
                Back
              </Button>
              <Button
                size="small"
                variant="primary"
                type="button"
                onClick={() =>
                  dispatch(
                    showDrawer("2fa", {
                      to: getValues("addressTo"),
                      value: getValues("value"),
                      tokenId: getValues("token"),
                      fee: getValues("checkboxFee"),
                      usdPrice: currentTo,
                    })
                  )
                }
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
