/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-modal";
import dayjs from "dayjs";
import "./Drawer.scss";
import { Wallet } from "@nextrope/chia-wallet-sdk";
import { Button } from "components/Button/Button";
import { FormInput } from "components/FormInput/FormInput";
import { Checkbox } from "components/Checkbox/Checkbox";
import { UnderlineButton } from "components/UnderlineButton/UnderlineButton";
import { CurrencyConverter } from "components/CurrencyConverter/CurrencyConverter";
import { ReactComponent as GasIcon } from "./img/gas.svg";
import {
  useAppDispatch,
  useAppSelector,
  useDatabaseContext,
} from "../../redux";
import {
  hideDrawer,
  setAmountFilter,
  setCoinFilter,
  setDataFilter,
} from "../../redux/features/ui/uiSlice";
import { config } from "../../config";
import { api } from "../../api/config";
import { ethers } from "ethers";
import { sliceWalletAddress } from "../../helpers";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import { useNavigate } from "react-router-dom";
import { AddAddressDrawer } from "./AddAddressDrawer/AddAddressDrawer";
import { DeleteAddressDrawer } from "./DeleteAddressDrawer/DeleteAddressDrawer";

Modal.setAppElement("#root");

export function Drawer() {
  const navigate = useNavigate();
  const drawer = useAppSelector((state) => state?.ui?.drawer);
  const dispatch = useAppDispatch();
  const tokensInfo = useAppSelector((state) => state.wallet?.tokensInfo);
  const keystore = localStorage.getItem("keystore");
  const walletAddress = useAppSelector((state) => state.auth?.walletAddress);
  const walletAddresses = useAppSelector(
    (state) => state.auth?.walletAddresses
  );
  const [password, setPassword] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const { db, databaseIsReady } = useDatabaseContext();
  const [isSendTransaction, setIsSendTransaction] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    resetField,
    watch,
    control,
  } = useForm();

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    reset: reset2,
  } = useForm();

  const [tokensSelectOptions, setTokensSelectOptions] = useState([]);

  useEffect(() => {
    setTokensSelectOptions([{ name: "all", value: "all" }]);

    const coinsArray = tokensInfo.map((el) => ({
      name: el.name,
      value: el.name,
    }));

    setTokensSelectOptions((prev) => [...prev, ...coinsArray]);

    if (tokensInfo.length > 0) setValue("coin", "all");
  }, [tokensInfo]);

  const [advance, setAdvance] = useState(false);

  const onHandleCreateTransaction = async () => {
    setIsSendTransaction(true);
    const { value, to, tokenId, fee, usdPrice } = drawer?.data;

    const tokenInfo = tokensInfo.find((el) => el.id == tokenId);

    const payment = ethers.utils.parseUnits(value, tokenInfo?.decimals);
    const convertFee = ethers.utils.parseUnits(fee, tokenInfo?.decimals);

    try {
      const wallet = Wallet.fromSerializedKeystore(keystore);

      console.log({
        name: tokenInfo?.name,
        payment,
        to,
        convertFee,
        password,
        derivPath: tokenInfo?.derivationPath,
      });

      const spendBundle = await wallet.createTransaction(
        config.API_URL,
        tokenInfo?.name,
        payment,
        to,
        convertFee,
        password,
        tokenInfo?.derivationPath
      );

      console.log(spendBundle);
      await api.post(`/fork/${tokenInfo?.name}/push_tx`, spendBundle);
      const obj = {
        amount: value,
        date: new Date(),
        from: walletAddresses.find(
          (walletAddress) => walletAddress.name === tokenInfo?.name
        ).address,
        to: to,
        network: String(tokenInfo?.name).toLowerCase(),
        usdPrice: usdPrice,
      };

      if (databaseIsReady) db.createObject(config.DB_STORE_TRANSACTION, obj);
      toast.success(
        "The transaction has been sent. Check your transaction history."
      );
      setIsSendTransaction(false);
      dispatch(hideDrawer());
      navigate("/transactions");
    } catch (error) {
      setIsSendTransaction(false);
      dispatch(hideDrawer());
      toast.error(`Transaction failed. Please try again later. ${error}`);
    }
  };

  const onHandleAddAddressBookRecord = async (data) => {
    try {
      const { name, address } = data;
      const obj = {
        name,
        address,
      };
      if (databaseIsReady) db.createObject(config.DB_STORE_ADDRESS_BOOK, obj);
      dispatch(hideDrawer());
      reset2();
      drawer.data.onSuccess();
    } catch (error) {
      toast.error(
        "Failed to add a record to the address book, try again later."
      );
    }
  };

  const onHandleSubmitDate = (data) => {
    dispatch(setDataFilter(data));
    dispatch(hideDrawer());
  };

  const onHandleCancelDate = () => {
    resetField("dateFrom", "");
    resetField("dateTo", "");
    dispatch(setDataFilter({ dateFrom: "", dateTo: "" }));
    dispatch(hideDrawer());
  };

  const onHandleSubmitAmount = (data) => {
    dispatch(setAmountFilter(data));
    dispatch(hideDrawer());
  };

  const onHandleCancelAmount = () => {
    resetField("minAmount", "");
    resetField("maxAmount", "");
    dispatch(setAmountFilter({ maxAmount: "", minAmount: "" }));
    dispatch(hideDrawer());
  };

  const onHandleSubmitCoin = (data) => {
    dispatch(setCoinFilter(data));
    dispatch(hideDrawer());
  };

  const onHandleCancelCoin = () => {
    resetField("coin", "all");
    dispatch(setCoinFilter({ coin: "all" }));
    dispatch(hideDrawer());
  };

  const convertFeeToUsd = useMemo(() => {
    return new BigNumber(getValues("checkboxFee") || "0")
      .multipliedBy(new BigNumber(drawer?.data?.transaction?.usdPrice || 0))
      .toFixed(2);
  }, [watch("checkboxFee")]);
  const convertValueToUsd = useMemo(() => {
    return new BigNumber(getValues("value") || "0")
      .multipliedBy(new BigNumber(drawer?.data?.transaction?.usdPrice || 0))
      .toFixed(2);
  }, [watch("value")]);

  const onHandleSendTransaction = async (data) => {
    setIsSendTransaction(true);

    const { to, derivationPath, network, decimals } = drawer?.data?.transaction;
    const payment = ethers.utils.parseUnits(data.value, decimals);
    const convertFee = ethers.utils.parseUnits(data.checkboxFee, decimals);
    try {
      const wallet = Wallet.fromSerializedKeystore(keystore);

      const spendBundle = await wallet.createTransaction(
        config.API_URL,
        network,
        payment,
        to,
        convertFee,
        data.password,
        derivationPath
      );

      await api.post(
        `/fork/${String(network).toLowerCase()}/push_tx`,
        spendBundle
      );
      const obj = {
        amount: data.value,
        date: new Date(),
        from: walletAddress,
        to: to,
        network: String(network).toLowerCase(),
        usdPrice: convertValueToUsd,
      };
      if (databaseIsReady) db.createObject(config.DB_STORE_TRANSACTION, obj);
      reset({
        value: "",
        checkboxFee: "",
        password: "",
      });
      toast.success(
        "The transaction has been sent. Check your transaction history."
      );
      setIsSendTransaction(false);

      dispatch(hideDrawer());
    } catch (error) {
      setIsSendTransaction(false);
      toast.error(`Transaction failed. Please try again r. ${error}`);
    }
  };
  const drawerContent = () => {
    switch (drawer?.type) {
      case "2fa":
        return (
          <div className="drawer-inner">
            <div className="drawer-top">
              <h3 className="drawer-title">Please enter your password</h3>
              <p className="drawer-description">
                Confirm your transaction by entering the password.
              </p>
            </div>
            <div className="drawer-bottom">
              <div style={{ paddingTop: "10px" }}>
                <FormInput
                  variant="password"
                  placeholder=" "
                  type={passwordInputType}
                  label="Password"
                  name="password"
                  onChange={(el) => setPassword(el.target?.value)}
                  onClick={() =>
                    setPasswordInputType((prevState) =>
                      prevState === "password" ? "text" : "password"
                    )
                  }
                />
              </div>
              <div className="buttons">
                {/*<Button type="button" variant="secondary" size="small">*/}
                {/*  Resend code*/}
                {/*</Button>*/}
                <Button
                  type="button"
                  variant="primary"
                  size="small"
                  onClick={onHandleCreateTransaction}
                  isLoading={isSendTransaction}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        );
      case "address-book-remove":
        return <DeleteAddressDrawer />;
      case "address-book-add":
        return <AddAddressDrawer />;
      case "add-to-favourite":
        return (
          <div className="drawer-inner">
            <div className="drawer-top">
              <p className="drawer-description">
                {drawer?.data?.name} successfully add to your favorite list
              </p>
            </div>
          </div>
        );
      case "remove-from-favourite":
        return (
          <div className="drawer-inner">
            <div className="drawer-top">
              <p className="drawer-description">
                {drawer?.data?.name} successfully remove from your favorite list
              </p>
            </div>
          </div>
        );
      case "transactions-filter-date":
        return (
          <form onSubmit={handleSubmit(onHandleSubmitDate)}>
            <div className="drawer-inner">
              <div className="drawer-top">
                <h3 className="drawer-title">Date</h3>
                <div className="input-wrapper">
                  {/* <FormInput
                  label="From"
                  name="form"
                  variant="date"
                  type="date"
                />
                <FormInput label="To" name="to" variant="date" type="date" /> */}
                  <FormInput
                    label="From"
                    variant="date"
                    type="date"
                    placeholder=""
                    {...register("dateFrom")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateFrom"
                    message="Choose date"
                  />
                  <FormInput
                    label="To"
                    variant="date"
                    type="date"
                    placeholder=""
                    {...register("dateTo")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="dateTo"
                    message="Choose date"
                  />
                </div>
              </div>
              <div className="drawer-bottom">
                <div className="buttons">
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={onHandleCancelDate}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" size="small">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      case "transactions-filter-coin":
        return (
          <form onSubmit={handleSubmit(onHandleSubmitCoin)}>
            <div className="drawer-inner">
              <div className="drawer-top">
                <h3 className="drawer-title">Coin</h3>
                <div className="input-wrapper">
                  <Controller
                    name="coin"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label="Choose Token"
                        variant="select"
                        type="select"
                        placeholder=""
                        value={value ? value : ""}
                        onChange={(e) => onChange(e)}
                        options={tokensSelectOptions}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="drawer-bottom">
                <div className="buttons">
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={onHandleCancelCoin}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" size="small">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      case "transactions-filter-amount":
        return (
          <form onSubmit={handleSubmit(onHandleSubmitAmount)}>
            <div className="drawer-inner">
              <div className="drawer-top">
                <h3 className="drawer-title">Amount</h3>
                <div className="select-wrapper">
                  <FormInput
                    label="Min amount"
                    variant="text"
                    type="number"
                    placeholder="Min amount"
                    {...register("minAmount")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="minAmount"
                    message="Fill amount"
                  />
                  <FormInput
                    label="Max amount"
                    variant="text"
                    type="number"
                    placeholder="Max amount"
                    {...register("maxAmount")}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="maxAmount"
                    message="Fill amount"
                  />
                </div>
              </div>
              <div className="drawer-bottom">
                <div className="buttons">
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={onHandleCancelAmount}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="primary" size="small">
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      case "transactions-filter-status":
        return (
          <div className="drawer-inner">
            <div className="drawer-top">
              <h3 className="drawer-title">Status</h3>
              <div className="input-wrapper">
                <Checkbox onChange={() => {}} name="send" errors={{}}>
                  Send
                </Checkbox>
                <Checkbox onChange={() => {}} name="received" errors={{}}>
                  Received
                </Checkbox>
              </div>
            </div>
            <div className="drawer-bottom">
              <div className="buttons">
                <Button type="button" variant="secondary" size="small">
                  Cancel
                </Button>
                <Button type="button" variant="primary" size="small">
                  Apply
                </Button>
              </div>
            </div>
          </div>
        );
      case "transactions-see-details":
        return (
          <div className="drawer-inner transactions">
            <div className="drawer-top">
              <h3 className="drawer-title">Details of transaction</h3>
              <div className="details">
                <div className="left">
                  <div className="amount">
                    {" "}
                    <span className="medium">Amount</span>
                    {/* <span>{drawer?.data?.trans?.amount}</span> */}
                    {/* <span className="gas">
                      Gas fee <GasIcon />
                    </span> */}
                  </div>
                </div>
                <div className="right">
                  <div className="amount">
                    <span className="medium">
                      {drawer?.data?.trans?.amount}
                    </span>
                    {/* <span>{drawer?.data?.transaction?.value[1]}</span> */}
                  </div>
                </div>
              </div>
              <div className="details-summary">
                <div className="left">
                  <span>Status</span>
                  <span>From</span>
                  <span>To</span>
                  <span>Date</span>
                </div>
                <div className="right">
                  <span>Done</span>
                  <span>
                    {sliceWalletAddress(drawer?.data?.trans?.from, 26)}
                  </span>
                  <span>{sliceWalletAddress(drawer?.data?.trans?.to, 26)}</span>
                  <span>
                    {dayjs(drawer?.data?.trans?.date).format("DD-MM-YYYY")}
                  </span>
                </div>
              </div>
            </div>
            <div className="drawer-bottom">
              <div className="total">
                <span className="large">Total Amount</span>
                <div>
                  <div className="amount">
                    <span className="large">{drawer?.data?.trans?.amount}</span>
                    {/* <span> {drawer?.data?.trans?.amount}</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case "transactions-send-tokens": {
        return (
          <form onSubmit={handleSubmit(onHandleSendTransaction)}>
            <div className="drawer-inner transactions">
              <div className="drawer-top">
                <h3 className="drawer-title">Send Tokens</h3>

                <div>
                  <div className="details-send">
                    <div className="left">
                      <div className="amount">
                        <span className="gas">
                          Gas fee <GasIcon />
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <UnderlineButton
                        onClick={() =>
                          setValue(
                            "value",
                            ethers.utils.formatUnits(
                              drawer?.data?.transaction?.balance,
                              drawer?.data?.transaction?.decimals
                            )
                          )
                        }
                      >
                        <span>Use Max Amount</span>
                      </UnderlineButton>
                    </div>
                  </div>
                  <div className="currency-converter">
                    <CurrencyConverter
                      id="currency-converter"
                      convertFrom={{
                        value: getValues("value"),
                        currency: drawer?.data?.transaction?.network,
                      }}
                      convertTo={{
                        value: convertValueToUsd,
                        currency: "$",
                      }}
                      {...register("value", {
                        required: "Field is requierd",
                        validate: {
                          isLessThan: (value) =>
                            Number(value) <=
                              ethers.utils.formatUnits(
                                drawer?.data?.transaction?.balance,
                                drawer?.data?.transaction?.decimals
                              ) || "Not enough funds",
                          notEnoughFunds: (value) =>
                            Number(value) + Number(getValues("checkboxFee")) <=
                              ethers.utils.formatUnits(
                                drawer?.data?.transaction?.balance,
                                drawer?.data?.transaction?.decimals
                              ) || "Not enough funds",
                          moreThan0: (value) =>
                            value > 0 || "The amount must be greater than 0",
                        },
                      })}
                    />
                    <ErrorMessage errors={errors} name="value" />
                  </div>
                </div>
                <div className="details-send">
                  <div className="left">
                    <div className="amount">
                      <span className="gas">
                        Gas fee <GasIcon />
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <UnderlineButton
                      onClick={() => setAdvance((prev) => !prev)}
                    >
                      {advance ? (
                        <span>See Basic Options</span>
                      ) : (
                        <span>See Advanced Options</span>
                      )}
                    </UnderlineButton>
                  </div>
                </div>
                {advance ? (
                  <div className="options-inner flex">
                    <CurrencyConverter
                      id="fee-amount-drawer"
                      size="big"
                      convertFrom={{
                        value: getValues("checkboxFee"),
                        currency: drawer?.data?.transaction?.network,
                      }}
                      convertTo={{
                        value: convertFeeToUsd,
                        currency: "$",
                      }}
                      {...register("checkboxFee", {
                        required: "Field is requierd",
                      })}
                    />
                    <ErrorMessage errors={errors} name="checkboxFee" />
                  </div>
                ) : (
                  <div className="options-inner">
                    <div className="checkbox">
                      <div>
                        <div className="alignItems">
                          <input
                            type="radio"
                            {...register("checkboxFee", {
                              required: "Field is requierd",
                            })}
                            value={ethers.utils.formatUnits(
                              config.STABLE_FAST_FEE_PRICE,
                              drawer?.data?.transaction?.decimals
                            )}
                          />
                          <label>Fast</label>
                        </div>
                        <ErrorMessage errors={errors} name="checkboxFee" />
                      </div>
                      <div className="value">
                        <span className="desc-top">
                          {ethers.utils.formatUnits(
                            config.STABLE_FAST_FEE_PRICE,
                            drawer?.data?.transaction?.decimals
                          )}{" "}
                          {drawer?.data?.transaction?.network}
                        </span>
                        <span className="desc-bottom">
                          {" "}
                          $
                          {new BigNumber(
                            ethers.utils.formatUnits(
                              config.STABLE_FAST_FEE_PRICE,
                              drawer?.data?.transaction?.decimals
                            )
                          )
                            .multipliedBy(
                              new BigNumber(
                                drawer?.data?.transaction?.usdPrice || 0
                              )
                            )
                            .toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="checkbox">
                      <div>
                        <div className="alignItems">
                          <input
                            type="radio"
                            {...register("checkboxFee", {
                              required: "Field is requierd",
                            })}
                            value={ethers.utils.formatUnits(
                              config.STABLE_NORMAL_FEE_PRICE,
                              drawer?.data?.transaction?.decimals
                            )}
                          />
                          <label>Normal</label>
                        </div>
                        <ErrorMessage errors={errors} name="checkboxFee" />
                      </div>
                      <div className="value">
                        <span className="desc-top">
                          {ethers.utils.formatUnits(
                            config.STABLE_NORMAL_FEE_PRICE,
                            drawer?.data?.transaction?.decimals
                          )}{" "}
                          {drawer?.data?.transaction?.network}
                        </span>
                        <span className="desc-bottom">
                          $
                          {new BigNumber(
                            ethers.utils.formatUnits(
                              config.STABLE_NORMAL_FEE_PRICE,
                              drawer?.data?.transaction?.decimals
                            )
                          )
                            .multipliedBy(
                              new BigNumber(
                                drawer?.data?.transaction?.usdPrice || 0
                              )
                            )
                            .toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="options">
                  <div className="details-summary">
                    <div className="left">
                      <span>From</span>
                      <span>To</span>
                      <span>Date</span>
                    </div>
                    <div className="right">
                      <span>
                        {sliceWalletAddress(
                          drawer?.data?.transaction?.from,
                          26
                        )}
                      </span>
                      <span>
                        {sliceWalletAddress(drawer?.data?.transaction?.to, 26)}
                      </span>
                      <span>
                        <span className="date-day">
                          {dayjs().format("DD-MM-YYYY")}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="drawer-bottom">
                <div className="total">
                  <span className="large">Total Amount</span>
                  <div>
                    <div className="amount">
                      <span className="large">
                        {new BigNumber(getValues("value") || 0)
                          .plus(+new BigNumber(getValues("checkboxFee") || 0))
                          .toString()}
                      </span>
                    </div>
                  </div>
                </div>
                <FormInput
                  variant="password"
                  placeholder=" "
                  type={passwordInputType}
                  label="Password"
                  {...register("password", {
                    required: "Field is requierd",
                  })}
                  onClick={() =>
                    setPasswordInputType((prevState) =>
                      prevState === "password" ? "text" : "password"
                    )
                  }
                />
                <ErrorMessage errors={errors} name="password" />

                <div className="buttons" style={{ marginTop: "16px" }}>
                  <Button
                    type="button"
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      reset({
                        value: "",
                        checkboxFee: "",
                        password: "",
                      });
                      dispatch(hideDrawer());
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="small"
                    isLoading={isSendTransaction}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      }
      default:
        return <div />;
    }
  };

  return (
    <Modal
      isOpen={drawer?.isVisible}
      contentLabel="Modal"
      className={{
        base: "drawer",
        afterOpen: "drawer_after-open",
        beforeClose: "drawer_before-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay_after-open",
        beforeClose: "overlay_before-close",
      }}
      onRequestClose={() => {
        dispatch(hideDrawer());
      }}
      closeTimeoutMS={500}
    >
      <>
        {drawerContent()}
        <button
          className="btn-close"
          onClick={() => {
            dispatch(hideDrawer());
          }}
        >
          <div className="icon" />
        </button>
      </>
    </Modal>
  );
}
