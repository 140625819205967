import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as IconHome } from "../../assets/menu/home.svg";
import { ReactComponent as IconPortfolio } from "../../assets/menu/portfolio.svg";
import { ReactComponent as IconSendReceive } from "../../assets/menu/send_receive.svg";
import { ReactComponent as IconSend } from "../../assets/menu/send.svg";
import { ReactComponent as IconReceive } from "../../assets/menu/receive.svg";
import { ReactComponent as IconClose } from "../../assets/menu/close.svg";
import { ReactComponent as IconTransactions } from "../../assets/menu/transactions.svg";
import { ReactComponent as IconSettings } from "../../assets/menu/settings.svg";
import "./Nav.scss";

export const Nav = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false);
  const [navIcon, setNavIcon] = useState(<IconSendReceive />);
  const [isTransaction, setIsStarnsaction] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/login-welcome" ||
      location.pathname === "/welcome" ||
      location.pathname === "/create" ||
      location.pathname === "/import"
    ) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    if (location.pathname === "/send") {
      setNavIcon(
        <>
          <IconSend />
          <span>Send</span>
        </>
      );
      setIsStarnsaction(true);
    } else if (location.pathname === "/receive") {
      setNavIcon(
        <>
          <IconReceive />
          <span>Receive</span>
        </>
      );
      setIsStarnsaction(true);
    } else {
      setNavIcon(<IconSendReceive />);
      setIsStarnsaction(false);
    }
  }, [location.pathname]);

  return visible ? (
    <div className="nav">
      <div className="tabs">
        <NavLink
          className={location.pathname !== "/dashboard" ? "inactiveTab" : ""}
          to="/dashboard"
        >
          <div className="icon stroke">
            <IconHome />
          </div>
          <span>Home</span>
        </NavLink>
        <NavLink
          className={location.pathname !== "/portfolio" ? "inactiveTab" : ""}
          to="/portfolio"
        >
          <div className="icon path">
            <IconPortfolio />
          </div>
          <span>Portfolio</span>
        </NavLink>
      </div>
      <div className="transaction-button">
        {navOpen ? (
          <div className="icon white-bg" onClick={() => setNavOpen(false)}>
            <IconClose />
          </div>
        ) : (
          <div
            className={isTransaction ? "icon white-bg" : "icon"}
            onClick={() => setNavOpen(true)}
          >
            {navIcon}
          </div>
        )}
        <Link
          className={navOpen ? "send-icon active" : "send-icon"}
          onClick={() => setNavOpen(false)}
          to="/send"
        >
          <>
            <IconSend />
            <span>Send</span>
          </>
        </Link>
        <Link
          className={navOpen ? "receive-icon active" : "receive-icon"}
          onClick={() => setNavOpen(false)}
          to="/receive"
        >
          <>
            <IconReceive />
            <span>Receive</span>
          </>
        </Link>
      </div>
      <div className="tabs">
        <NavLink
          className={location.pathname !== "/transactions" ? "inactiveTab" : ""}
          to="/transactions"
        >
          <div className="icon path">
            <IconTransactions />
          </div>
          <span>History</span>
        </NavLink>
        <NavLink
          className={location.pathname !== "/settings" ? "inactiveTab" : ""}
          to="/settings"
        >
          <div className="icon stroke">
            <IconSettings />
          </div>
          <span>Settings</span>
        </NavLink>
      </div>
    </div>
  ) : null;
};
