/* eslint-disable */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import s from "./HomeTile.module.scss";
import { ReactComponent as StarIcon } from "./img/star.svg";
import { ReactComponent as StarIconWithoutBg } from "./img/starWithoutBg.svg";
import { typeguardFavouriteCoins } from "helpers";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { showDrawer } from "../../redux/features/ui/uiSlice";
import { useAppDispatch } from "../../redux";

type HomeTileProps = {
  coin: any;
  variant?: "dashboard";
};

export const HomeTile = ({ coin, variant }: HomeTileProps) => {
  const isProfit = (buyPrice: number, price: number) => buyPrice > price;
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const uknownFavouriteCoin = localStorage.getItem("favouriteCoins");
    let favouriteIcon = typeguardFavouriteCoins(uknownFavouriteCoin);
    if (favouriteIcon.includes(coin.id)) setIsFavourite(true);
  }, []);
  const handleChangeRoute = (e: any) => {
    if (e.target.tagName !== "BUTTON") {
      navigate(`/dashboard/details/${coin.id}`);
    }
  };

  const onHandleToogleToLocalStorage = () => {
    const uknownFavouriteCoin = localStorage.getItem("favouriteCoins");
    let favouriteIcon = typeguardFavouriteCoins(uknownFavouriteCoin);
    const index = favouriteIcon.findIndex((el) => el === coin?.id);

    if (index === -1) {
      favouriteIcon.push(coin?.id);
      localStorage.setItem("favouriteCoins", JSON.stringify(favouriteIcon));
      setIsFavourite(true);
      dispatch(showDrawer("add-to-favourite", { name: coin.name }));
    } else {
      favouriteIcon.splice(index, 1);
      setIsFavourite(false);
      localStorage.setItem("favouriteCoins", JSON.stringify(favouriteIcon));
      dispatch(showDrawer("remove-from-favourite", { name: coin.name }));
    }
  };

  return (
    <div
      // onClick={(e) => handleChangeRoute(e)}
      className={s.tile}
    >
      <div className={s.left}>
        <h3 className={s.coinName}>{coin.name}</h3>
        <div className={s.coinValue}>
          <span className={s.value}>
            {ethers.utils.formatUnits(coin?.balance, coin?.decimals)}
          </span>
          <span className={s.coinPrice}>
            $ {new BigNumber(coin?.usdValue).toFixed(2)}
          </span>
        </div>
      </div>

      {variant === "dashboard" ? (
        <div className={s.right}>
          <div
            className={cx(
              s.rightContent,
              isProfit(coin.buyPrice, coin.price) ? s.loss : ""
            )}
          >
            <span className={s.procnets}>
              {coin.profit && `(${coin.profit})`}
            </span>
            <div className={s.profit}>
              <span className={s.profitValue}>$ 0.00</span>
              <div className={s.desc}>Farmed</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={s.right}>
          <div
            className={cx(
              s.rightContent,
              isProfit(coin.buyPrice, coin.price) ? s.loss : ""
            )}
          >
            <span className={s.procnets}>({coin.profit})</span>
            <div className={s.profit}>
              <span className={s.profitValue}>$ 0.00</span>
              <div className={s.desc}>Market Price</div>
            </div>
          </div>
          <button onClick={onHandleToogleToLocalStorage} className={s.cta}>
            {isFavourite ? (
              <StarIcon />
            ) : (
              <div className={s.startWithoutBg}>
                <StarIcon />
                <StarIconWithoutBg />
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
