import React, { ReactNode } from "react";
import cx from "classnames";
import s from "./ListModal.module.scss";

type ListModalType = {
  children: ReactNode;
  showModal: boolean;
  modalRef?: React.ForwardedRef<HTMLDivElement>;
};

export const ListModal = React.forwardRef<HTMLDivElement, ListModalType>(
  ({ children, showModal, modalRef }) => (
    <div
      ref={modalRef}
      className={cx(s.listModal, showModal ? s.active : null)}
    >
      {children}
    </div>
  )
);
