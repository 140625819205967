/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { TransactionsList } from "./TransactionsList";
import s from "./Transactions.module.scss";
import { TransactionsFilter } from "./TransactionsFilter";
import { useWindowDimensions } from "../../hooks";
import { TransactionsFilterDesktop } from "./TransactionsFilterDesktop";
import {
  useAppDispatch,
  useAppSelector,
  useDatabaseContext,
} from "../../redux";
import { Pagination } from "../../components";
import { config } from "../../config";
import { Helmet } from "react-helmet-async";
import { TransactionsActiveFilter } from "./TransactionsActiveFilter";
import { setFilter } from "../../redux/features/ui/uiSlice";

export type FilterPropType = {
  dateFrom: null | string;
  dateTo: null | string;
  maxAmount: null | string;
  minAmount: null | string;
  coin: string;
};

const filterTabs = [
  {
    id: 1,
    name: "Date",
    type: "filter",
    tabType: "date",
  },
  {
    id: 2,
    name: "Amount",
    type: "filter",
    tabType: "amount",
  },
  {
    id: 3,
    name: "Coin",
    type: "filter",
    tabType: "coin",
  },
  // {
  //   id: 4,
  //   name: "Status",
  //   type: "filter",
  //   tabType: "status",
  // },
  // {
  //   id: 5,
  //   name: "Clear all filters",
  //   type: "clear",
  // },
];

const pageLimit = 4;
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const Transactions = () => {
  const activeMobileFilters = useAppSelector((state) => state?.ui?.filterData);
  const methods = useForm({});
  const dispatch = useAppDispatch();
  const [showInput, setShowInput] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const screenSize = useWindowDimensions();
  const showDesktopFilter = screenSize.width >= 1020;
  const { db, databaseIsReady } = useDatabaseContext();
  const [countDb, setcountDb] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataFetch, setDataFetch] = useState<databaseTransactionType[]>([]);
  const [dataSort, setDataSort] = useState<databaseTransactionType[]>([]);
  const [filterProp, setFilterProp] = useState<FilterPropType>({
    dateFrom: null,
    dateTo: null,
    maxAmount: null,
    minAmount: null,
    coin: "all",
  });

  const filterData = useAppSelector((state) => state?.ui?.filterData);

  useEffect(() => {
    if (databaseIsReady) {
      db?.countData(config.DB_STORE_TRANSACTION, (value: number) => {
        if (value !== 0) setcountDb(value);
      });

      db?.getAllObjects(
        config.DB_STORE_TRANSACTION,
        (_array: databaseTransactionType[]) => {
          setDataFetch(_array.reverse());
        }
      );
    }
  }, []);

  useEffect(() => {
    setFilterProp(filterData);
  }, [filterData]);

  const paginateArray = (
    _array: databaseTransactionType[],
    _pageNumber: number,
    _pageLimit: number
  ) => _array.slice((_pageNumber - 1) * _pageLimit, _pageNumber * _pageLimit);

  const sortArrayByAmount = (
    _array: databaseTransactionType[],
    _minAmount: string | null,
    _maxAmount: string | null
  ) => {
    if (
      (_maxAmount && _maxAmount.length) ||
      (_minAmount && _minAmount.length)
    ) {
      const newArray = _array.filter((item) => {
        if (_maxAmount && !_minAmount)
          return Number(item.amount) <= Number(_maxAmount);
        if (_minAmount && !_maxAmount)
          return Number(item.amount) >= Number(_minAmount);
        if (_minAmount && _maxAmount)
          return (
            Number(item.amount) >= Number(_minAmount) &&
            Number(item.amount) <= Number(_maxAmount)
          );
      });

      return newArray.sort((a, b) => Number(a.amount) - Number(b.amount));
    }

    return _array;
  };

  const sortArrayByDate = (
    _array: databaseTransactionType[],
    _dateFrom: string | null,
    _dateTo: string | null
  ) => {
    if (
      (_dateFrom && _dateFrom.length > 0) ||
      (_dateTo && _dateTo.length > 0)
    ) {
      const startDay = dayjs(_dateFrom);
      const finishDay = dayjs(_dateTo);
      const finishDayForBetween = dayjs(_dateTo)
        .add(24, "hours")
        .subtract(1, "second");

      const newArray = _array.filter((item) => {
        if (_dateFrom && !_dateTo)
          return dayjs(item.date).isSameOrAfter(startDay, "day");
        if (_dateTo && !_dateFrom)
          return dayjs(item.date).isSameOrBefore(finishDay, "day");
        if (_dateFrom && _dateTo)
          return dayjs(item.date).isBetween(
            startDay,
            finishDayForBetween,
            "hour"
          );
      });

      return newArray.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }

    return _array;
  };

  const sortArrayByCoin = (
    _array: databaseTransactionType[],
    _coin: string | null
  ) => {
    if (_coin === "all") {
      const newArray = _array.filter((item) => item);
      return newArray;
    }
    if (_coin && _coin.length > 0) {
      const newArray = _array.filter((item) => item.network === _coin);
      return newArray;
    }

    return _array;
  };

  useEffect(() => {
    if (dataFetch && dataFetch.length > 0) {
      const arrayByDate = sortArrayByDate(
        dataFetch,
        filterProp.dateFrom,
        filterProp.dateTo
      );
      const arrayByAmount = sortArrayByAmount(
        arrayByDate,
        filterProp.minAmount,
        filterProp.maxAmount
      );
      const arrayByCoin = sortArrayByCoin(arrayByAmount, filterProp.coin);
      const newArray = paginateArray(arrayByCoin, pageNumber, pageLimit);
      setcountDb(arrayByCoin.length);
      setDataSort(newArray);
    }
  }, [dataFetch, pageNumber, filterProp]);

  // const handleToggleShowInput = () => {
  //   setShowInput((prevState) => !prevState);
  // };

  const onHandleFilters = (data: FilterPropType) => {
    dispatch(setFilter({ ...data }));
    setFilterProp(data);
    setPageNumber(1);
  };

  const onHandleClearFilters = (type: string) => {
    if (type === "all") {
      setFilterProp({
        minAmount: null,
        maxAmount: null,
        coin: "all",
        dateTo: null,
        dateFrom: null,
      });
      setPageNumber(1);
    }
    if (type === "date") {
      setFilterProp((prev) => ({
        ...prev,
        dateTo: null,
        dateFrom: null,
      }));
    }
    setPageNumber(1);
    if (type === "amount") {
      setFilterProp((prev) => ({
        ...prev,
        minAmount: null,
        maxAmount: null,
      }));
      setPageNumber(1);
    }
    if (type === "coin") {
      setFilterProp((prev) => ({
        ...prev,
        coin: "all",
      }));
    }
    setPageNumber(1);
  };

  useEffect(() => {
    if (activeMobileFilters) {
      setFilterProp(activeMobileFilters);
    }
  }, [activeMobileFilters]);

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Transactions</title>
      </Helmet>
      <FormProvider {...methods}>
        <section className={s.transactions}>
          <header className={s.header}>
            {/* <div className={cx(s.searchBar, showInput ? s.active : null)}>
            <button onClick={handleToggleShowInput} className={s.searchBtn}>
              <div className={s.searchIcon}>
                <SearchIcon />
              </div>
            </button>
            {showInput && (
              <div className={s.inputWrapper}>
                <input
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                  className={s.input}
                  type="text"
                />
              </div>
            )}
          </div> */}
            {!showInput &&
              (showDesktopFilter ? (
                <div className={s.filterFormWrapper}>
                  <TransactionsFilterDesktop
                    filterTabs={filterTabs}
                    handleFilters={onHandleFilters}
                    activeFilters={filterProp}
                  />
                </div>
              ) : (
                <TransactionsFilter filterTabs={filterTabs} />
              ))}
          </header>
          <div className={s.listWrapper}>
            {showDesktopFilter ? (
              <TransactionsActiveFilter
                handleClearFilters={onHandleClearFilters}
                activeFilters={filterProp}
              />
            ) : null}
            <TransactionsList transactionsData={dataSort} />
            <Pagination
              className="pagination-bar"
              currentPage={pageNumber}
              totalCount={countDb}
              pageSize={pageLimit}
              onPageChange={(page: number) => setPageNumber(Number(page))}
              siblingCount={0}
            />
          </div>
        </section>
      </FormProvider>
    </>
  );
};
