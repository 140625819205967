import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "./img/back.svg";
import s from "./BackButton.module.scss";

export const BackButton = () => {
  const navigation = useNavigate();
  return (
    <button onClick={() => navigation(-1)} className={s.button}>
      <span className={s.iconWrapper}>
        <BackIcon />
      </span>
      Back
    </button>
  );
};
