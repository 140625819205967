/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HomeDetailsTile } from "./HomeDetailsTile";
import s from "./HomeDetails.module.scss";
import { FormInput } from "components/FormInput/FormInput";
import ChartDetails from "./ChartDetails";
import ChartFinancialDetails from "./ChartFinancialDetails";
import { DateTime } from "luxon";
import "chartjs-adapter-luxon";
import { useAppDispatch, useAppSelector } from "../../redux";
import { Helmet } from "react-helmet-async";

export const HomeDetails = () => {
  const params = useParams();
  const coins = useAppSelector((state) => state.wallet?.tokensInfo);
  const dispatch = useAppDispatch();
  const [chartType, setChartType] = useState("FARMED");

  const barCount = 30;
  const initialDateStr = "01 Apr 2017 00:00 Z";
  const farmedData = [20, 25, 34, 10, 11, 22, 15];
  const barData = getRandomData(initialDateStr, barCount);

  const getRandomInt = function (max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  };

  function randomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  function randomBar(date: any, lastClose: any) {
    const open = +randomNumber(lastClose * 0.95, lastClose * 1.05).toFixed(2);
    const close = +randomNumber(open * 0.95, open * 1.05).toFixed(2);
    const high = +randomNumber(
      Math.max(open, close),
      Math.max(open, close) * 1.1
    ).toFixed(2);
    const low = +randomNumber(
      Math.min(open, close) * 0.9,
      Math.min(open, close)
    ).toFixed(2);
    return {
      x: date.valueOf(),
      o: open,
      h: high,
      l: low,
      c: close,
    };
  }

  function getRandomData(dateStr: any, count: number) {
    let date = DateTime.fromRFC2822(dateStr);
    const data = [randomBar(date, 30)];
    while (data.length < count) {
      date = date.plus({ days: 1 });
      if (date.weekday <= 5) {
        data.push(randomBar(date, data[data.length - 1].c));
      }
    }
    return data;
  }

  const coin = coins?.filter(
    (coin: any) => JSON.stringify(coin.id) === params.coinId
  )[0];

  const selectOption = [
    {
      name: "1 W",
      value: "1",
    },
    {
      name: "24 H",
      value: "24",
    },
    {
      name: "12 H",
      value: "12",
    },
    {
      name: "4 H",
      value: "4",
    },
  ];

  return (
    <>
      <Helmet>
        <title>EcoWay Wallet - Home - Details</title>
      </Helmet>
      <div className={s.homeDetails}>
        <HomeDetailsTile coin={coin} />
        <div className={s.chartGroup}>
          <header className={s.chartHeader}>
            <div className={s.chartTypes}>
              <div
                className={chartType === "FARMED" ? s.activeChart : ""}
                onClick={() => setChartType("FARMED")}
              >
                Farmed
              </div>
              <div
                className={chartType === "PRICE" ? s.activeChart : ""}
                onClick={() => setChartType("PRICE")}
              >
                Price
              </div>
            </div>
            <div className={s.selectWrapper}>
              <FormInput
                placeholder="Search"
                name="to"
                variant="select"
                type="select"
                options={selectOption}
              />
            </div>
          </header>
          {chartType === "FARMED" ? (
            <ChartDetails data={farmedData} />
          ) : (
            <ChartFinancialDetails data={barData} />
          )}
        </div>
      </div>
    </>
  );
};
