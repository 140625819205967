import {
  createSlice,
  Slice,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import * as greenweb from "greenwebjs";
import { Wallet, generationUtils } from "@nextrope/chia-wallet-sdk";
import { getHashesHelper } from "../../../helpers";
import { getTokensInfo } from "../wallet/walletSlice";

export const handleCreate = createAsyncThunk(
  "auth/handleCreate",
  async ({ password, mnemonic }: HandleCreate, { dispatch }) => {
    await greenweb.clvm.initialize();
    const wallet = await Wallet.create(password, mnemonic);
    const keystore = wallet.serializeKeystore();
    const hashes = await getHashesHelper(password, wallet);
    const { tokensInfo } = await dispatch(getTokensInfo(hashes)).unwrap();
    localStorage.setItem("keystore", keystore);
    const walletAddress = generationUtils.generateAddressFromPublicKey(
      wallet.publicKey
    );
    const walletAddresses = tokensInfo.map(({ symbol, name }) => {
      const address = generationUtils.generateAddressFromPublicKey(
        wallet.publicKey,
        symbol
      );
      return {
        address,
        name,
      };
    });
    return { wallet, keystore, walletAddress, walletAddresses, hashes };
  }
);

export const handleLogin = createAsyncThunk(
  "auth/handleLogin",
  async ({ password, keystore }: HandleLogin, { dispatch }) => {
    const wallet = Wallet.fromSerializedKeystore(keystore);
    const checkPassword = await wallet.checkPassword(password);
    const hashes = await getHashesHelper(password, wallet);
    const { tokensInfo } = await dispatch(getTokensInfo(hashes)).unwrap();
    const walletAddress = generationUtils.generateAddressFromPublicKey(
      wallet.publicKey
    );
    const walletAddresses = tokensInfo.map(({ symbol, name }) => {
      const address = generationUtils.generateAddressFromPublicKey(
        wallet.publicKey,
        symbol
      );
      return {
        address,
        name,
      };
    });
    return { wallet, checkPassword, hashes, walletAddress, walletAddresses };
  }
);

export const handleImport = createAsyncThunk(
  "auth/handleImport",
  async ({ password, mnemonic }: HandleCreate, { dispatch }) => {
    const wallet = await Wallet.create(password, mnemonic);
    const keystore = wallet.serializeKeystore();
    const checkPassword = await wallet.checkPassword(password);
    const hashes = await getHashesHelper(password, wallet);
    const { tokensInfo } = await dispatch(getTokensInfo(hashes)).unwrap();
    localStorage.setItem("keystore", keystore);
    const walletAddress = generationUtils.generateAddressFromPublicKey(
      wallet.publicKey
    );
    const walletAddresses = tokensInfo.map(({ symbol, name }) => {
      const address = generationUtils.generateAddressFromPublicKey(
        wallet.publicKey,
        symbol
      );
      return {
        address,
        name,
      };
    });
    return {
      wallet,
      keystore,
      checkPassword,
      hashes,
      walletAddress,
      walletAddresses,
    };
  }
);

export const handleLogout = createAction("auth/handleLogout");

interface AuthState {
  isAuth: boolean;
  isLoading: boolean;
  createWalletInProgress: boolean;
  error: string | null;
  wallet: Wallet | null;
  walletAddress: string | null;
  walletAddresses: WalletAddresses | null;
  keystore: string | null;
  hashes: PuzzleHash[];
}

const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  createWalletInProgress: false,
  error: null,
  wallet: null,
  walletAddress: null,
  walletAddresses: null,
  keystore: localStorage.getItem("keystore"),
  hashes: [],
};

export const authSlice: Slice<AuthState> = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state: AuthState) => {
      state.isAuth = false;
      state.wallet = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleCreate.pending, (state: AuthState) => {
      state.isLoading = true;
      state.createWalletInProgress = true;
    });
    builder.addCase(handleCreate.fulfilled, (state: AuthState, { payload }) => {
      const { keystore, wallet, walletAddress, walletAddresses, hashes } = {
        ...payload,
      };
      state.isLoading = false;
      state.error = null;
      state.keystore = keystore;
      state.isAuth = true;
      state.createWalletInProgress = false;
      state.wallet = wallet;
      state.walletAddress = walletAddress;
      state.walletAddresses = walletAddresses;
      state.hashes = hashes;
    });
    builder.addCase(handleCreate.rejected, (state: AuthState, { error }) => {
      state.isLoading = false;
      state.createWalletInProgress = false;
      state.error = error.message || "";
    });

    builder.addCase(handleLogin.pending, (state: AuthState) => {
      state.isLoading = true;
    });
    builder.addCase(handleLogin.fulfilled, (state: AuthState, { payload }) => {
      const { checkPassword, wallet, hashes, walletAddress, walletAddresses } =
        { ...payload };
      state.isAuth = checkPassword;
      state.hashes = hashes;
      state.isLoading = false;
      state.error = null;
      state.createWalletInProgress = false;
      state.wallet = wallet;
      state.walletAddress = walletAddress;
      state.walletAddresses = walletAddresses;
    });
    builder.addCase(handleLogin.rejected, (state: AuthState, { error }) => {
      state.isLoading = false;
      state.createWalletInProgress = false;
      state.error = error.message || "";
    });

    builder.addCase(handleImport.pending, (state: AuthState) => {
      state.isLoading = true;
    });
    builder.addCase(handleImport.fulfilled, (state: AuthState, { payload }) => {
      const {
        checkPassword,
        wallet,
        keystore,
        hashes,
        walletAddress,
        walletAddresses,
      } = {
        ...payload,
      };
      state.isAuth = checkPassword;
      state.wallet = wallet;
      state.keystore = keystore;
      state.hashes = hashes;
      state.isLoading = false;
      state.error = null;
      state.walletAddress = walletAddress;
      state.walletAddresses = walletAddresses;
    });
    builder.addCase(handleImport.rejected, (state: AuthState, action) => {
      state.isLoading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(handleLogout, (state) => {
      state.isAuth = false;
      state.wallet = null;
      state.walletAddress = null;
      state.walletAddresses = null;
    });
  },
});
