/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import s from "components/SignedInHeader/SignedInHeader.module.scss";
import { ButtonCopyToClipboard } from "components/ButtonCopyToClipboard/ButtonCopyToClipboard";
import EcowayLogo from "../../assets/ecoway-logo.svg";
import { useAppSelector } from "../../redux";

interface Props {
  location: any;
}

export const SignedInHeader: React.FC<Props> = ({ location }) => {
  const { walletAddresses } = useAppSelector((state) => state.auth);

  const [selectedWallet, setSelectedWallet] = useState<WalletAddress | null>(
    null
  );

  const handleSelectWallet = (wallet: WalletAddress) => {
    setSelectedWallet(wallet);
  };

  useEffect(() => {
    if (walletAddresses) {
      setSelectedWallet(walletAddresses[0]);
    }
  }, [walletAddresses]);

  return (
    <header
      className={
        location.pathname === "/receive" || location.pathname === "/send"
          ? cx(s.header, s.isHome, s.isTransaction)
          : cx(s.header, s.isHome)
      }
    >
      <Link className={s.logo} to="/">
        <img src={EcowayLogo} alt="ecoway" />
      </Link>
      {selectedWallet && walletAddresses && walletAddresses.length > 0 && (
        <div className={s.addressesWrapper}>
          <div className={s.copyHolder}>
            <ButtonCopyToClipboard
              copyText={selectedWallet.address}
              name={selectedWallet.name}
            />
          </div>
          <ul className={s.walletsContainer}>
            {walletAddresses.map(({ name, address }: WalletAddress) => (
              <li
                className={s.walletItem}
                onClick={() => handleSelectWallet({ name, address })}
              >
                <div>
                  <span className={s.bold}>{name} - </span>
                  <span>{address}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};
