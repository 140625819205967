import { Button } from "../../Button/Button";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { hideDrawer } from "../../../redux/features/ui/uiSlice";
import drawerStyles from "../Drawer.module.scss";

export const DeleteAddressDrawer = () => {
  const drawer = useAppSelector((state) => state?.ui?.drawer);
  const dispatch = useAppDispatch();

  return (
    <div className={drawerStyles.wrapper}>
      <h3 className={drawerStyles.wrapper__title}>Delete</h3>
      <p className={drawerStyles.wrapper__description}>
        Do you really want to remove this address?
      </p>
      <div className={drawerStyles.wrapper__buttons}>
        <Button
          onClick={() => dispatch(hideDrawer())}
          type="button"
          variant="secondary"
          size="small"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            drawer?.data?.onClick();
            dispatch(hideDrawer());
          }}
          type="button"
          variant="primary"
          size="small"
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
